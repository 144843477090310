import { useMemo } from "react";
import { bottomNop, bottomThrow, bottomWithDefault } from "@eatbetter/common-shared";
import {
  ScalingMeasurement,
  ScalingAndConversionInfo,
  UnitConversion,
  ScalingQuantityUnit,
  ScalingCompoundAdditiveMeasurement,
} from "@eatbetter/items-shared";
import { RecipeInstruction } from "@eatbetter/recipes-shared";
import { CookingSessionInstructionTimer } from "@eatbetter/cooking-shared";
import {
  getAdditiveQuantityUnitString,
  getAlternatesQuantityUnitString,
  getQuantityUnitString,
} from "./conversions/QuantityUnitDisplay";
import { log } from "../../Log";
import { CurrentEnvironment } from "../../CurrentEnvironment.ts";
import { ChangedString } from "./conversions/Format.ts";

export interface RecipeTextToken {
  type: "text";
  isModified: boolean;
  text: string;
  /**
   * the indices of the original (unmodified) string that relate to the text
   */
  range: [number, number];

  tooltipInfo?: { original: string; modified: string };
}

export interface CookingTimerText {
  type: "timer";
  timer: CookingSessionInstructionTimer;
  text: string;
  /**
   * the indices of the original (unmodified) string that relate to the text)
   */
  range: [number, number];
}

export interface ScalableText {
  text: string;
  scaling?: ScalingAndConversionInfo;
}

export const useScaled = (
  i: ScalableText | undefined,
  scale: number,
  conversion: UnitConversion
): RecipeTextToken[] => {
  return useMemo(() => {
    if (!i) {
      return [];
    }
    return scaleAndConvertRecipeText(i, scale, conversion);
  }, [i, scale, conversion]);
};

export const useScaledInstruction = (
  i: RecipeInstruction | undefined,
  timers: CookingSessionInstructionTimer[],
  scale: number,
  conversion: UnitConversion
): Array<RecipeTextToken | CookingTimerText> => {
  return useMemo(() => {
    if (!i) {
      return [];
    }

    return scaleInstructionWithTimers(i, timers, scale, conversion);
  }, [i, timers, scale, conversion]);
};

export function scaleInstructionWithTimers(
  instruction: RecipeInstruction,
  timers: CookingSessionInstructionTimer[],
  scale: number,
  conversion: UnitConversion
): Array<RecipeTextToken | CookingTimerText> {
  return scaleAndConvertRecipeTextInternal(instruction, scale, conversion, timers);
}

export function scaleAndConvertRecipeText(
  i: ScalableText,
  scale: number,
  conversion: UnitConversion
): RecipeTextToken[] {
  return scaleAndConvertRecipeTextInternal(i, scale, conversion, []) as RecipeTextToken[];
}

type RangeAndChanges = { type: "rangeAndChanges"; range: [number, number]; changes: ChangedString[] };

export function scaleAndConvertRecipeTextInternal(
  i: ScalableText,
  scale: number,
  conversion: UnitConversion,
  timers: CookingSessionInstructionTimer[]
): Array<RecipeTextToken | CookingTimerText> {
  try {
    const measurements = i.scaling?.measurements ?? [];
    const scalingNoOp = (scale === 1 && conversion === "original") || measurements.length === 0;
    if (scalingNoOp && timers.length === 0) {
      return [{ type: "text", text: i.text, isModified: false, range: [0, i.text.length - 1] }];
    }

    // The goal here is to structure the data so that we can build the tooltips for each changed string. The tooltip
    // is meant to cover the full quantity unit pair. So, for "1 cup onions", the tooltip should show original text of "1 cup".
    // Things get more interesting in the case of nested measurements like "1 14-oz can tomatoes". In this case, we have 2 measurements:
    // 1 can, which scales, and 14-oz, which converts only. For this case, we want the tool tip to coer "1 14-oz can".
    // To do this, get the overall range for each qu and additive qu. We break alternates down into their components.
    // then sort by order ascending and map to a RangeAndChanges. For each changed string when we scale the measurements
    // we add the change to the relevant RangeAndChanges
    // Since we used the original ranges of the measurements to build these ranges, we know that each should be fully
    // contained within a range
    // and since we sort ascending, the changes related to 14-oz in the example above, will match to the "parent" qu, 1 can
    // and nothing should map to the range we create for 14-oz
    const rangesAndChanges = measurements
      .flatMap<[number, number]>(m => {
        switch (m.type) {
          case "qu":
            return [getRangeFromQu(m)];
          case "additive":
            return [getRangeFromQu(m)];
          case "alternates":
            return m.measurements.map(getRangeFromQu);
          default:
            return bottomWithDefault(m, [], "scaleAndConvertRecipeTextInternal");
        }
      })
      .sort((a, b) => a[0] - b[0])
      .map<RangeAndChanges>(range => ({ type: "rangeAndChanges", range, changes: [] }));

    const changes = measurements.flatMap(m =>
      getChangedValues(m, m.scale ? scale : 1, m.noConvert ? "original" : conversion)
    );
    changes.forEach(c => addChangeToRange(i, c, rangesAndChanges));

    // at this point, we have mapped all of the changed strings to the parent range. Remove any ranges that don't have any changes
    // and then combine with timers.
    const allChanges = [...rangesAndChanges.filter(r => r.changes.length > 0), ...timers].sort((a, b) => {
      // sort by start of range and then by end of range
      if (a.range[0] !== b.range[0]) {
        return a.range[0] - b.range[0];
      }

      return a.range[1] - b.range[1];
    });

    const tokens: Array<RecipeTextToken | CookingTimerText> = [];
    const text = i.text;
    let currentIndex = 0;

    allChanges.forEach(change => {
      const before = text.substring(currentIndex, change.range[0]);
      if (before.length > 0) {
        tokens.push({ type: "text", text: before, isModified: false, range: [currentIndex, change.range[0] - 1] });
      }

      if (change.type === "instructionTimer") {
        tokens.push({
          type: "timer",
          timer: change,
          range: change.range,
          text: text.substring(change.range[0], change.range[1] + 1),
        });
      } else if (change.type === "rangeAndChanges") {
        tokens.push(...getTokensFromRangeAndChange(i.text, change));
      } else {
        bottomNop(change);
      }

      currentIndex = change.range[1] + 1;
    });

    const after = text.substring(currentIndex);
    if (after.length > 0) {
      tokens.push({ type: "text", text: after, isModified: false, range: [currentIndex, text.length - 1] });
    }

    return tokens;
  } catch (err) {
    if (CurrentEnvironment.isTest() || CurrentEnvironment.isCI()) {
      throw err;
    }

    log.errorCaught("scaleRecipeText(): unexpected error caught. Returning unmodified text", err, {
      i,
      scale,
      conversion,
    });

    return [{ type: "text", text: i.text, isModified: false, range: [0, i.text.length - 1] }];
  }
}

/**
 * Given a qu or additive qu, find the overall range based on the q and u tokens.
 * So, for "1 cup onions", it should be the range that covers "1 cup"
 * For "1 (14-oz) can tomatoes" and the qu for 1 can, it should be the range that covers "1 (14-oz) can", even though
 * there is another measurment in between the q and u in this case.
 */
function getRangeFromQu(qu: ScalingQuantityUnit | ScalingCompoundAdditiveMeasurement): [number, number] {
  const components = qu.type === "qu" ? [qu] : qu.measurements;
  const ranges = components.flatMap(c => {
    const cr = c.q.map(r => r.range);
    if (c.u?.range) {
      cr.push(c.u.range);
    }
    return cr;
  });

  const min = Math.min(...ranges.map(r => r[0]));
  const max = Math.max(...ranges.map(r => r[1]));

  return [min, max];
}

function addChangeToRange(phrase: ScalableText, c: ChangedString, ranges: RangeAndChanges[]): void {
  for (const rac of ranges) {
    if (c.indices[0] >= rac.range[0] && c.indices[1] <= rac.range[1]) {
      rac.changes.push(c);
      return;
    }
  }

  log.error("No containing range found in scaleAndConvertRecipeTextInternal", { changedString: c, phrase });
}

/**
 * Given a range for a qu, and the associated changes, produce the recipe text tokens along with the tooltip info.
 * for the tooltip modified value, we map over the resulting tokens
 * for the original value, we use the original string and the range from the RangeAndChanges
 */
function getTokensFromRangeAndChange(text: string, rac: RangeAndChanges): RecipeTextToken[] {
  let currentIndex = rac.range[0];

  type RecipeTextTokenAndMeta = RecipeTextToken & {
    trailingSpaceInserted?: boolean;
  };

  const tokens: RecipeTextTokenAndMeta[] = [];
  // these must be sorted ascending for the string manipulation to work
  rac.changes.sort((a, b) => {
    if (a.indices[0] !== b.indices[0]) {
      return a.indices[0] - b.indices[0];
    }

    const aInsert = a.insert ? 1 : 0;
    const bInsert = b.insert ? 1 : 0;

    // insert should come before non-insert
    return bInsert - aInsert;
  });
  rac.changes.forEach(change => {
    const before = text.substring(currentIndex, change.indices[0]);
    if (before.length > 0) {
      tokens.push({ type: "text", text: before, isModified: false, range: [currentIndex, change.indices[0] - 1] });
    }

    // REVIEW - This was ported from prior logic, but I'm unclear if there is ever a case where we should have a ChangedString
    // object and isModified is false.
    // Skip modified entries where the output equals exactly the input. This prevents converted but not scaled entries
    // such as temperature from getting highlighted even though it wasn't modified.
    const originalText = change.insert ? "" : text.substring(change.indices[0], change.indices[1] + 1);
    const isModified = change.text !== originalText;

    const lastChar = tokens.at(-1)?.text.at(-1);
    const spaceBefore = change.insert && lastChar && lastChar !== " " ? " " : "";
    const spaceAfter = change.insert ? " " : "";

    tokens.push({
      type: "text",
      text: `${spaceBefore}${change.text}${spaceAfter}`,
      isModified,
      range: change.indices,
      trailingSpaceInserted: spaceAfter.length > 0,
    });

    // Don't advance the index if it's an insert - it wasn't present in the original string
    if (change.insert) {
      currentIndex = change.indices[1];
    } else {
      currentIndex = change.indices[1] + 1;
    }
  });

  if (currentIndex <= rac.range[1]) {
    const after = text.substring(currentIndex, rac.range[1] + 1);
    if (after.length > 0) {
      tokens.push({ type: "text", text: after, isModified: false, range: [currentIndex, rac.range[1]] });
    }
  }

  // we blindly add a trailing space in the code above, because we don't yet know if it's needed since we don't know what comes next.
  // now we remove that space if the following character is a space or period.
  const extraSpaceRemoved = tokens.map((t, idx) => {
    if (!t.trailingSpaceInserted) {
      return t;
    }

    // if this is the last token, or the next token starts with " " or "." (characters that don't require an extra space)
    // remove what we added.
    const next = tokens[idx + 1];
    if (!next || next.text.startsWith(" ") || next.text.startsWith(".")) {
      return {
        ...t,
        text: t.text.substring(0, t.text.length - 1),
      };
    }

    return t;
  });

  const original = text.substring(rac.range[0], rac.range[1] + 1);
  const modified = extraSpaceRemoved.map(t => t.text).join("");
  const tooltipInfo: RecipeTextToken["tooltipInfo"] = { original, modified };
  return extraSpaceRemoved.map(t => ({ ...t, tooltipInfo, trailingSpaceInserted: undefined }));
}

function getChangedValues(m: ScalingMeasurement, scale: number, conversion: UnitConversion): Array<ChangedString> {
  switch (m.type) {
    case "qu": {
      return getQuantityUnitString({ qu: m, scale, conversion });
    }
    case "additive": {
      const additive = getAdditiveQuantityUnitString({ additive: m, scale, conversion });
      return additive ? [additive] : [];
    }
    case "alternates": {
      return getAlternatesQuantityUnitString({ qu: m, scale, conversion });
    }
    default:
      bottomThrow(m);
  }
}
