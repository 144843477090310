import {
  addHours,
  bottomLog,
  bottomNop,
  bottomThrow,
  daysBetween,
  defaultTimeProvider,
  Envelope,
  EpochMs,
  getPathAndQuery,
  newId,
  requestHeaderNames,
  sleep,
  StructuredError,
  UrlString,
  UserId,
} from "@eatbetter/common-shared";
import {
  AppCreateUserArgs,
  AuthedUser,
  isStructuredUserError,
  UpdateUserProfileArgs,
  UserErrorTypes,
  UserInvalidProfileArgsError,
  UsernameAvailableResult,
  UserProfileLink,
} from "@eatbetter/users-shared";
import { SyncThunkAction, ThunkAction } from "../redux/Redux";
import { log } from "../../Log";
import { getLinkForNavigableScreen, getScreenAndPropsFromPath, NavApi } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import {
  authedUserRequested,
  authedUserReceived,
  authedUserErrored,
  userCanceledSignIn,
  userSignInErrored,
  diagnosticModeDisabled,
  diagnosticModeEnabled,
  impersonateUserSelected,
  appMetadataRequested,
  appMetadataReceived,
  appMetadataAvailable,
  appMetadataErrored,
  emailSigninLinkReceived,
  userSignedInEvent,
  userCreateDataReceived,
  newUserAuthed,
  userStartedSignIn,
  navActionRequested,
  emailAuthStarted,
  householdMembersChanged,
  checkpointsUpdateStarted,
  checkpointsUpdateSucceeded,
  checkpointsUpdateErrored,
  appleFullNameReceived,
  numericCheckpointsUpdated,
  anonymousUserCreated,
  checkpointsCompleted,
  homeScreenOnboardingShouldBeShown,
  appSignInLinkReceivedInWeb,
  appReset,
  userStartedSignOut,
  appResetStarted,
  userSettingsUpdateStarted,
  userSettingsUpdateSucceeded,
  userSettingsUpdateErrored,
} from "./SystemSlice";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import {
  reportAnonymousAccountCreated,
  reportAppReset,
  reportAppSignInLinkOnWeb,
  reportBioUpdated,
  reportDeletedUserAttemptedSignIn,
  reportEmailSignInLinkSent,
  reportIssueReported,
  reportNameUpdated,
  reportNonHttpSignInLink,
  reportOnboardingCompleted,
  reportProfileLinkUpdated,
  reportProfilePhotoUpdated,
  reportReviewPromptMaybeOpened,
  reportUserAccountCreated,
  reportUserAccountDeleted,
  reportUsernameUpdated,
  reportUserReceived,
  reportUserSignedIn,
  reportUserSignedOut,
} from "../analytics/AnalyticsEvents";
import { gzip } from "../util/Gzip";
import { SetWaitingHandler } from "../Types";
import { cleanupTimerNotificationsOnSignOut } from "../cooking/CookingTimerThunks";
import { ApiClientBase } from "../ApiClientBase";
import { CurrentEnvironment } from "../../CurrentEnvironment";
import {
  AuthProviderUserInfo,
  Deps,
  SendEmailSigninLinkResult,
  SignInWithAppleResult,
  SignInWithEmailLinkResult,
  SignInWithGoogleResult,
} from "../Deps";
import { AnalyticsEventAndProperties, AnalyticsEventPropertyMap, ReportIssueData } from "@eatbetter/composite-shared";
import { PartialRecipeId } from "@eatbetter/recipes-shared";
import { saveSharedRecipe } from "../recipes/RecipesThunks";
import { Platform } from "react-native";
import { mergeUpdates } from "../redux/ItemWithUpdates";
import { Buffer } from "buffer";
import { loadNewHomeFeedPosts, loadNewProfilePosts } from "../social/SocialThunks";
import { getNameFromAppleFullName, selectCheckpoints } from "./SystemSelectors";
import { selectRecipeStats } from "../recipes/RecipesSelectors";
import { Alert } from "../../components/Alert/Alert";
import { loadNewNotifications } from "../notifications/NotificationsThunks";
import { selectNotificationUnreadCount } from "../notifications/NotificationsSelectors";
import { selectFollowingCountForOnboarding, selectPostCountForOnboarding } from "../social/SocialSelectors";

const strings = {
  emailAlreadyInUseAlert: {
    title: "Email already in use",
    signIn: "Sign In",
    cancel: "Cancel",
    message: (email?: string) =>
      `${
        email ?? "This email"
      } is already associated with a Deglaze account. To sign into the existing account, tap Sign In below. To create a new account with a different email address, tap Cancel.`,
  },
};

/**
 * In the case of a background task, we want to make sure that redux-persist has finished bootstrapping when the app
 * is spun up before wea start mucking with state. Otherwise, the changes could just get blown away.
 * This returns a promise that will resolve when redux-persist has restored state and will reject if it doesn't
 * happen within the specified timeout (default of 2 seconds).
 * @param timeout
 */
export const waitForReduxPersist = (timeout = 2000): ThunkAction<void> => {
  return async (_dispatch, _getState, deps) => {
    log.info("Thunk: waitForReduxPersist");

    if (deps.reduxPersistor.getState().bootstrapped) {
      return;
    }

    let unsubscribe: (() => void) | undefined;

    return new Promise<void>((resolve, reject) => {
      const timeoutHandle = setTimeout(reject, timeout);

      const checkIfBootstrapped = () => {
        if (deps.reduxPersistor.getState().bootstrapped) {
          clearTimeout(timeoutHandle);
          resolve();
        }
      };

      unsubscribe = deps.reduxPersistor.subscribe(checkIfBootstrapped);

      checkIfBootstrapped();
    })
      .catch(err => {
        log.errorCaught("Error in waitForReduxPersist", err);
      })
      .finally(() => unsubscribe?.());
  };
};

export const maybePromptForReview = (
  trigger: AnalyticsEventPropertyMap["Review Prompt Action"]
): SyncThunkAction<void> => {
  return (dispatch, getState, deps) => {
    log.info("Thunk: maybePromptForReview");

    try {
      if (!deps.storeReview) {
        return;
      }

      const state = getState();

      if (!state.system.authedUser.data?.isRegistered) {
        return;
      }

      const checkpoints = selectCheckpoints(state);
      const lastPrompted = (checkpoints.reviewRequested ?? 1) as EpochMs;
      if (daysBetween(lastPrompted, defaultTimeProvider()) < 7) {
        return;
      }

      // this checkpoint currently gets set when a user reports a recipe issue, a content issue,
      // leaves feedback, or gets an unexpected error. Even though these might not be negative in nature,
      // be conservative and don't show the prompt for a couple weeks. If they are still around after that amount
      // of time, they have likely gotten over it.
      const lastNegative = (checkpoints.lastNegativeInteraction ?? 1) as EpochMs;
      if (daysBetween(lastNegative, defaultTimeProvider()) < 14) {
        return;
      }

      const thresholds = state.system.appMetadata.data?.promptThresholds;

      if (!thresholds) {
        return;
      }

      const userCreated = state.system.authedUser.data?.created ?? defaultTimeProvider();

      const stats = selectRecipeStats(state);
      const recipeActionCount = stats.addedToListCount + stats.cookedCount;

      const metThreshold = thresholds.find(t => {
        return (
          daysBetween(userCreated, defaultTimeProvider()) >= t.daysSinceAccountCreated &&
          stats.recipeCount >= t.recipeCount &&
          recipeActionCount >= t.recipeActionCount
        );
      });

      if (metThreshold) {
        // update checkpoint first to avoid retrying at this point, although the actions to trigger this aren't
        // *that* common.
        dispatch(numericCheckpointsUpdated({ reviewRequested: defaultTimeProvider() }));
        deps.storeReview.maybeOpenPrompt();
        // report it
        dispatch(analyticsEvent(reportReviewPromptMaybeOpened({ trigger, thresholds: metThreshold })));
      }
    } catch (err) {
      log.errorCaught("Error in maybePromptForReview", err);
    }
  };
};

export const deepLinkReceived = (link: UrlString): SyncThunkAction<void> => {
  return (dispatch, _getState, deps) => {
    log.info(`Thunk: deepLinkReceived with link ${link}`);
    let deepLink = link;

    // we handle this one manually
    // this starts the app reset flow if the user taps
    // a deglaze:///reset link
    const u = new URL(link);
    if (u.pathname.toLowerCase() === "/reset") {
      dispatch(appResetStarted());
      return;
    }

    if (deps.auth.isEmailSigninLink(link)) {
      // Sample link
      // https://web.mooklab-dev.link/?link=https://premium-botany-297107.firebaseapp.com/__/auth/action?apiKey%3DAIzaSyDxNaVA7KNB0Dn5tmk9DufOh5dx0zcyX-8%26mode%3DsignIn%26oobCode%3DHvag5E2KDpwTjVHdqOSFwAfRylXXo4VfG5TBlrORgE8AAAGJZ762Qw%26continueUrl%3Dhttps://web.mooklab-dev.link/sign-in/email/check?email%253Djacob%25252B20230717%252540deglaze.app%26lang%3Den&ibi=app.deglaze.dev&ifl=https://premium-botany-297107.firebaseapp.com/__/auth/action?apiKey%3DAIzaSyDxNaVA7KNB0Dn5tmk9DufOh5dx0zcyX-8%26mode%3DsignIn%26oobCode%3DHvag5E2KDpwTjVHdqOSFwAfRylXXo4VfG5TBlrORgE8AAAGJZ762Qw%26continueUrl%3Dhttps://web.mooklab-dev.link/sign-in/email/check?email%253Djacob%25252B20230717%252540deglaze.app%26lang%3Den

      const isAppSignInUrl = isEmailSignInLinkForApp(link);
      log.info(`Got email sign-in link for ${isAppSignInUrl ? "app" : "web"}`);

      if (Platform.OS === "web" && isAppSignInUrl) {
        // replace the link with the app custom url scheme
        const appLinkAndEmail = getCustomSchemeUrlAndEmail(link);
        dispatch(analyticsEvent(reportAppSignInLinkOnWeb(appLinkAndEmail.email)));
        dispatch(appSignInLinkReceivedInWeb({ original: link, app: appLinkAndEmail.url }));
      } else if (Platform.OS !== "web") {
        // To keep things simple and to handle cases where the email is not in state, we have the
        // CheckYourEmailScreen handle the logic around the link.
        // On web, the __/auth endpoint hosted by firebase redirects there directly.
        // But in the app we have to nav there, because the auth link itself is not for that screen (see example above), ideally with
        // the email address passed along. So, we parse the link to try to get the continueUrl, which has what we need
        // but first, default to the screen with no email passed
        let parsed = false;
        deepLink = getLinkForNavigableScreen(navTree.get.screens.checkYourEmail);

        const continueUrl = getContinueUrlFromSignInLink(link);
        if (continueUrl) {
          deepLink = continueUrl as UrlString;
          log.info(`Parsed continueUrl from email sign-in link: ${continueUrl}`);
          parsed = true;
        }

        if (!parsed) {
          log.warn("Got email sign-in link that could not be parsed. Nav'ing to CheckYourEmailScreen witout args", {
            link,
          });
        }
      }

      // get the protocol to figure out if it's http/https
      const sUrl = new URL(link);
      if (!sUrl.protocol.startsWith("http")) {
        const { email } = getCustomSchemeUrlAndEmail(link);
        dispatch(analyticsEvent(reportNonHttpSignInLink(sUrl.protocol, email)));
      }

      // I wasn't able to trigger this, but in theory, if the check your email screen is already focused
      // and the sign-in happens quickly enough, it could nav before the requested nav action is handled.
      // This could result in naving the user back to the check your email screen. So adding a tiny delay
      // to reduce the chances. The "real" fix here might be to provide more granular control over deep link
      // handlign based on auth status.
      setTimeout(() => dispatch(emailSigninLinkReceived(link)), 50);
    }

    // On web, the screen is loaded automatically, so nothing else to do
    if (Platform.OS === "web") {
      return;
    }

    const pathAndQs = getPathAndQuery(deepLink);
    const screenAndProps = getScreenAndPropsFromPath(pathAndQs);

    if (!screenAndProps || !screenAndProps.screen.deeplink) {
      log.warn(`Got non-actionable deeplink: ${link}`);
      return;
    }

    const { screen, props } = screenAndProps;

    log.info(`Dispatching navActionRequested with screenName ${screen.name} and props ${JSON.stringify(props)}`);
    dispatch(navActionRequested({ screenName: screen.name, props }));
  };
};

export const getAppMetadata = (): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: getAppMetadata");
    try {
      const startTime = deps.time.epochMs();
      dispatch(appMetadataRequested(startTime));
      const api = deps.api.withThrow();
      const resp = await api.getAppMetadata();

      if (resp.data) {
        dispatch(
          appMetadataReceived({
            startTime,
            data: resp.data,
          })
        );

        log.info("Retrieved app metadata. Dispatching appMetadataAvailable");
        // this is a seperate event so the consuming slices jsut get the data
        // directly and not the server data object
        dispatch(appMetadataAvailable(resp.data));

        if (resp.data.appExpired) {
          log.logRemote("AppMetadata had appExpired property set. Navigating to AppUpgradeScreen");
          dispatch(navActionRequested({ screenName: "appUpgrade", props: resp.data.appExpired }));
        }
      }
    } catch (err) {
      log.errorCaught("Unexpected error in getAppMetadata", err);
      dispatch(appMetadataErrored());
      throw err;
    }
  };
};

export const deletedUserSignedIn = (): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: deletedUserSignedIn");
    // this needs to come before the sign-out so mixpanel identify is still there
    dispatch(analyticsEvent(reportDeletedUserAttemptedSignIn()));
    await doSignout(deps);
    dispatch(userSignInErrored("userDeleted"));
  };
};

export const signOutClicked = (nav: NavApi): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: signOutClicked");
    dispatch(userStartedSignOut());
    dispatch(analyticsEvent(reportUserSignedOut()));
    await doSignout(deps);
    nav.goTo("reset", navTree.get.screens.signIn);
    await cleanupTimerNotificationsOnSignOut();
  };
};

export const resetApp = (): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: resetApp");
    dispatch(analyticsEvent(reportAppReset()));
    // we are overly generous with delays to try to keep things
    // straightforward and to introduce a little delay in the UI
    // so the screen is in focus for a couple seconds
    // wait a bit of time for mixpanel event to fire before
    // we sign out. This should be more than enough.
    await sleep(1000);
    await doSignout(deps);
    await cleanupTimerNotificationsOnSignOut();
    // add time to let state "settle" after signing out
    await sleep(1000);
    // before we blow it all away
    dispatch(appReset());
    await sleep(500);
  };
};

async function doSignout(deps: Deps) {
  await deps.auth.signOut().catch(err => {
    log.errorCaught("Error calling deps.signOut", err, {}, "warn");
  });
  deps.mixpanel?.logOut();
  deps.firebaseAnalytics?.logOut();
}

export const impersonateUser = (userId: UserId | undefined, nav: NavApi): ThunkAction<void> => {
  return async (dispatch, _getState, _deps) => {
    log.info("Thunk: impersonateUser");
    await dispatch(signOutClicked(nav));
    dispatch(impersonateUserSelected({ userId }));
    // on auth, we'll set the impersonation headers. See function below and the call to it in userAuthed thunk
  };
};

export const setImpersonateUserHeader = (userId: UserId | undefined) => {
  const headerName = requestHeaderNames.impersonateHeader;
  if (userId) {
    ApiClientBase.addAdditionalMetaHeader(headerName, userId);
  } else {
    ApiClientBase.removeAdditionalMetaHeader(headerName);
  }
};

export const areTabsMounted = (): SyncThunkAction<boolean> => {
  return (_dispatch, getState, _deps) => {
    return getState().system.navigation.tabsMounted;
  };
};

export const getUserOrHandleNewUser = (args: {
  userNotFoundBehavior: "createAccount" | "throwError";
}): ThunkAction<void> => {
  return async (dispatch, _getState, _deps) => {
    log.info("Thunk: getUserOrHandleNewUser");
    const user = await dispatch(getAuthedUser({ throwOnError: false }));

    if (user.data) {
      dispatch(userSignedInEvent({ userId: user.data.userId }));
    } else if (user.error.code === "users/userNotFound") {
      log.info("Received users/userNotFound");

      if (args.userNotFoundBehavior === "throwError") {
        log.error("Caught error in getUserOrHandleNewUser thunk and userNotFoundBehavior is throwError. Throwing.");
        throw new StructuredError(user.error);
      }

      if (user.error.payload.createData) {
        log.info("Received createData. Dispatching.");
        dispatch(userCreateDataReceived(user.error.payload.createData));
      }

      // this drives the nav to the create account screen
      dispatch(newUserAuthed());
    } else if (user.error) {
      throw new StructuredError(user.error);
    } else {
      throw new Error("Expecting a structured error but didn't get one");
    }
  };
};

export const getAuthedUser = (args: { throwOnError: boolean }): ThunkAction<Envelope<AuthedUser, UserErrorTypes>> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: getAuthedUser");
    try {
      const startTime = deps.time.epochMs();
      dispatch(authedUserRequested(startTime));
      const api = args?.throwOnError ? deps.api.withThrow() : deps.api.withReturn();

      const resp = await api.getAuthedUser();

      if (resp.data) {
        // see if the number of household users has changed
        const existingMembers = getState().system.authedUser.data?.household.length;

        if (existingMembers !== undefined && existingMembers !== resp.data.household.length) {
          log.info("Household member count has changed. Dispatching householdMembersChanged");
          dispatch(householdMembersChanged(deps.time.epochMs()));
        }

        dispatch(
          authedUserReceived({
            startTime,
            data: resp.data,
          })
        );

        dispatch(analyticsEvent(reportUserReceived(resp.data)));

        if (resp.data.appExpired) {
          log.logRemote("Received appExpired from authedUser");
          dispatch(navActionRequested({ screenName: "appUpgrade", props: resp.data.appExpired }));
        }
      }

      return resp;
    } catch (err) {
      log.errorCaught("Unexpected error in getAuthedUser", err);
      dispatch(authedUserErrored());
      throw err;
    }
  };
};

export const anonymousSigninCompletedStorageKey = "anonUserCreated";

export const showAnonymousOption = (): ThunkAction<boolean> => {
  return async (_dispatch, _getState, deps) => {
    // note: this is set in userAuthed in AppLifecycle. It is set for *any* sign-in method, so that a user
    // never sees the anonymous option 1) after they sign in anonymously once OR 2) after they sign in with
    // a non-anonymous option.
    const anonCreatd = await deps.asyncStorage.getItem(anonymousSigninCompletedStorageKey);
    return !anonCreatd;
  };
};

export const createAnonymousUser = (setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: createAnonymousUser");

    try {
      setWaiting?.(true);
      await deps.auth.signInAnonymously();
      dispatch(analyticsEvent(reportAnonymousAccountCreated()));
      dispatch(anonymousUserCreated());
    } finally {
      setWaiting?.(false);
    }
  };
};

export const signInWithEmailAndPassword = (email: string, password: string): ThunkAction<boolean> => {
  return async (dispatch, _gs, deps) => {
    log.info("Thunk: signInWithEmailAndPassword");

    if (!deps.auth.signInWithEmailAndPassword) {
      const msg = "signInWithEmailAndPassword called but function is not defined on deps";
      log.error(msg);
      throw new Error(msg);
    }

    try {
      dispatch(userStartedSignIn("emailPassword"));
      const signedIn = await deps.auth.signInWithEmailAndPassword(email, password);
      log.info(`Result of signInWithEmailAndPassword is ${signedIn}`);

      return signedIn;
    } catch (err) {
      dispatch(userCanceledSignIn());
      log.warn("Error while calling signInWithEmailAndPassword", { err });
      return false;
    }
  };
};

export const sendPhoneSigninSms = (phoneNumber: string, forceResend?: boolean): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    dispatch(userStartedSignIn("sms"));
    await deps.auth.sendPhoneSigninSms?.(phoneNumber, forceResend ?? false);
  };
};

export const signInWithPhoneSmsCode = (code: string): ThunkAction<void> => {
  return async (_dispatch, _getState, deps) => {
    if (deps.auth.signInWithPhoneSmsCode) {
      await deps.auth.signInWithPhoneSmsCode(code);
    }
  };
};

export const sendEmailSignInLink = (email: string, redirectPath?: string): ThunkAction<SendEmailSigninLinkResult> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: sendEmailSignInLink");

    dispatch(userStartedSignIn("emailLink"));
    dispatch(emailAuthStarted({ email }));
    const domain = CurrentEnvironment.firebaseDynamicLinkDomain();
    log.info(`Sending email signIn link for domain ${domain}`);
    const continueUrl = getLinkForNavigableScreen(navTree.get.screens.checkYourEmail, { email, redirectPath });
    const res = await deps.auth.sendEmailSigninLink(email, domain, continueUrl);
    if (res.success) {
      dispatch(analyticsEvent(reportEmailSignInLinkSent(email)));
    }

    return res;
  };
};

export const signInWithEmailLink = (
  email: string,
  link: string,
  nav: NavApi
): ThunkAction<SignInWithEmailLinkResult> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: signInWithEmailLink");
    const result = await deps.auth.signInWithEmailLink(email, link);
    await dispatch(handleAnonUserLinked(result, nav));
    return result;
  };
};

export const signInWithGoogle = (nav: NavApi): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: signInWithGoogle");

    dispatch(userStartedSignIn("google"));

    try {
      const result = await deps.auth.signInWithGoogle();
      log.info(`Result of signInWithGoogle is success: ${result.success} and result: ${result.result}`);
      if (!result.success && result.result.code === "userCanceled") {
        dispatch(userCanceledSignIn());
        return;
      }

      await dispatch(handleAnonUserLinked(result, nav));

      // if the user successfully signed in, they auth change event handler
      // will fire - nothing else is necessary
    } catch (err) {
      log.warn("Error while calling signInWithGoogle", { err });
      dispatch(userSignInErrored("general"));
    }
  };
};

export const signInWithApple = (nav: NavApi): ThunkAction<void> => {
  return async (dispatch, _gs, deps) => {
    log.info("Thunk: signInWithApple");

    dispatch(userStartedSignIn("apple"));

    try {
      const result = await deps.auth.signInWithApple!();
      log.info(`Result of signInWithApple is ${JSON.stringify(result)}`);
      if (!result.success && result.result.code === "userCanceled") {
        dispatch(userCanceledSignIn());
        return;
      }

      // if the user successfully signed in, the auth change event handler will fire
      if (result.success && result.result.fullName) {
        dispatch(appleFullNameReceived(result.result.fullName));

        const name = getNameFromAppleFullName(result.result.fullName);

        if (name) {
          await deps.api
            .withThrow()
            .saveAppleName({ name })
            .catch(err => {
              log.errorCaught("Error attempting to save Apple name from signInWithApple", err, {
                appleName: result.result.fullName,
              });
            });
        }
      }

      await dispatch(handleAnonUserLinked(result, nav));
    } catch (err) {
      log.errorCaught("Error while calling signInWithApple", err, {}, "warn");
      dispatch(userSignInErrored("general"));
    }
  };
};

export const getAuthProviderUserInfo = (): ThunkAction<AuthProviderUserInfo | undefined> => {
  return (_dispatch, _getState, deps) => {
    return deps.auth.getAuthProviderUserInfo();
  };
};

const handleAnonUserLinked = (
  result: SignInWithGoogleResult | SignInWithAppleResult | SignInWithEmailLinkResult,
  nav: NavApi
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: handleAnonUserLinked");
    if (result.success && result.result.op === "anonUserLinked") {
      const state = getState();
      if (state.system.preAuthData.signInMethod) {
        const authProviderUser = await deps.auth.getAuthProviderUserInfo();
        const event = reportUserSignedIn({
          signInMethod: state.system.preAuthData.signInMethod,
          email: authProviderUser?.email?.address,
          anonymousAccountLinked: true,
        });
        dispatch(analyticsEvent(event));
      }

      // report email for attribution
      try {
        const au = await deps.auth.getAuthProviderUserInfo();
        if (au?.email?.address) {
          deps.firebaseAnalytics?.setEmail(au.email.address);
          deps.appsFlyer?.setEmail(au.email.address);
        }
      } catch (err) {
        log.errorCaught(
          "Error while calling setEmail for firebaseAnalytics/appsFlyer after anonymous account linked",
          err
        );
      }

      await dispatch(getUserOrHandleNewUser({ userNotFoundBehavior: "createAccount" }));
    } else if (!result.success && result.result.code === "anonLinkFailedCredsInUse") {
      log.logRemote("User attempted to link an email that is already in use.");
      Alert.alert(strings.emailAlreadyInUseAlert.title, strings.emailAlreadyInUseAlert.message(result.result.email), [
        {
          type: "cancel",
          text: strings.emailAlreadyInUseAlert.cancel,
          onPress: () => dispatch(userCanceledSignIn()),
        },
        {
          type: "save",
          text: strings.emailAlreadyInUseAlert.signIn,
          // we sign out to get them to the sign in scren,
          onPress: () => dispatch(signOutClicked(nav)),
        },
      ]);
    }
  };
};

export type CreateEditProfileResult =
  | "success"
  | "nameInvalid"
  | "usernameInvalid"
  | "usernameUnavailable"
  | "bioInvalid"
  | "linksInvalid";
export const createUser = (
  args: Omit<AppCreateUserArgs, "referredBy">,
  setWaiting?: SetWaitingHandler
): ThunkAction<CreateEditProfileResult> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: createUser");

    try {
      setWaiting?.(true);
      const state = getState();
      const referredBy = state.system.preAuthData.referredBy;
      const sharedRecipeId = state.system.preAuthData.sharedRecipeId;
      const sourceRecipeId = state.system.preAuthData.sharedSourceRecipeId;

      const fullArgs: AppCreateUserArgs = {
        ...args,
        referredBy,
      };

      await deps.api.withThrow().createUser(fullArgs);
      const wasAnonymous = !!state.system.anonymousUserCreated;
      const event = reportUserAccountCreated(wasAnonymous, args.nameFromAuthProvider);
      dispatch(analyticsEvent(event));

      if (referredBy && sharedRecipeId) {
        // make a best effort to save the recipe that was shared with the user.
        await dispatch(
          saveSharedRecipe(
            {
              sharedByUserId: referredBy,
              sourceRecipeId: sourceRecipeId ?? sharedRecipeId,
              sharedByRecipeId: sharedRecipeId,
              partialRecipeId: newId<PartialRecipeId>(),
            },
            "userShared"
          )
        ).catch(err => {
          log.errorCaught("Error calling saveSharedRecipe in createUser thunk", err, { referredBy, sharedRecipeId });
        });
      }

      await dispatch(getUserOrHandleNewUser({ userNotFoundBehavior: "throwError" }));
      return "success";
    } catch (err) {
      if (isStructuredUserError(err)) {
        if (err.data.code === "users/invalidProfileArgs") {
          return invaldProfileArgsErrorToResult(err.data.payload);
        } else if (err.data.code === "users/userConflict") {
          await dispatch(getUserOrHandleNewUser({ userNotFoundBehavior: "throwError" }));
          return "success";
        }
      }

      throw err;
    } finally {
      setWaiting?.(false);
    }
  };
};

export const submitUserOnboarding = (setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: submitUserOnboarding");

    try {
      setWaiting?.(true);
      // regardless of what happens below, we don't want the user to end up back in onboarding
      // if they've made it this far, so set the checkpoint.
      dispatch(checkpointsCompleted(["onboarding_questions_completed"]));

      const state = getState();
      const responses = state.system.onboardingQuestions;
      const notificationPermissionsGranted = !!state.system.pushPermission?.havePermission;

      await deps.api.withThrow().userOnboarding({
        household: responses.household ?? false,
        organize: responses.organize ?? false,
        tryGroceries: responses.tryGroceries ?? false,
        ingestionSources: responses.ingestionSources ?? [],
        discoverySource: responses.discoverySource,
        discoverySourceOtherText: responses.discoverySourceOtherText,
        discoverySourceSurveyIndex: responses.discoverySourceSurveyIndex ?? -1,
      });

      await Promise.all([dispatch(loadNewNotifications()), dispatch(loadNewHomeFeedPosts("followingFeed"))]);

      const updatedState = getState();
      const notificationCount = selectNotificationUnreadCount(updatedState);
      const followingCount = selectFollowingCountForOnboarding(updatedState);
      const postCount = selectPostCountForOnboarding(updatedState);

      dispatch(
        analyticsEvent(
          reportOnboardingCompleted({
            responses,
            entitiesFollowed: followingCount,
            followingPostCount: postCount,
            notificationPermissionsGranted,
          })
        )
      );

      if (notificationCount) {
        dispatch(homeScreenOnboardingShouldBeShown());
      }
    } finally {
      setWaiting?.(false);
    }
  };
};

export const deleteAccount = (navApi: NavApi, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: deleteAccount");

    try {
      setWaiting?.(true);
      await deps.api.withThrow(setWaiting).deleteAccount();
      const event = reportUserAccountDeleted();
      dispatch(analyticsEvent(event));
      await dispatch(signOutClicked(navApi));
    } finally {
      setWaiting?.(false);
    }
  };
};

export const updateUserProfile = (
  args: UpdateUserProfileArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<CreateEditProfileResult> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: updateUserProfile");

    try {
      const currentUser = getState().system.authedUser.data;

      if (!currentUser?.isRegistered) {
        log.error("Attempted to call updateUserProfile for anonymous user", { currentUser });
        throw new Error("Attempted to call updateUserProfile for anonymous user");
      }

      await deps.api.withThrow().updateUserProfile(args);

      const analyticsEvents: AnalyticsEventAndProperties[] = [];
      Object.entries(args).forEach(e => {
        const field = e[0] as keyof UpdateUserProfileArgs;
        const updated = e[1];

        if (updated === undefined || !currentUser) {
          return;
        }

        switch (field) {
          case "username":
            analyticsEvents.push(reportUsernameUpdated({ old: currentUser.username, updated }));
            break;
          case "name":
            analyticsEvents.push(reportNameUpdated({ old: currentUser.name, updated }));
            break;
          case "photo":
            analyticsEvents.push(reportProfilePhotoUpdated({ old: currentUser.photo, updated }));
            break;
          case "bio":
            analyticsEvents.push(reportBioUpdated({ old: currentUser.profileBio, updated }));
            break;
          case "links": {
            const oldLink = currentUser.profileLinks[0]?.url ?? "undefined";
            const newLink = (updated as UserProfileLink[])[0]?.url ?? "undefined";
            analyticsEvents.push(reportProfileLinkUpdated({ old: oldLink, updated: newLink }));
            break;
          }
          default:
            bottomLog(field, "updateUserProfile thunk mixpanel");
        }
      });

      analyticsEvents.forEach(e => dispatch(analyticsEvent(e)));

      await Promise.all([
        dispatch(getAuthedUser({ throwOnError: false })),
        // currently, we use the SocialProfileInfo returned from this call to render the profile screen.
        // This was done to give us an easy way to add more profile info that is not a part of the AuthedUser
        dispatch(loadNewProfilePosts()).catch(err =>
          log.errorCaught("Error calling loadNewProfilePosts in updateUserProfile", err)
        ),
      ]);

      return "success";
    } catch (err) {
      if (isStructuredUserError(err)) {
        if (err.data.code === "users/invalidProfileArgs") {
          return invaldProfileArgsErrorToResult(err.data.payload);
        }
      }

      throw err;
    } finally {
      setWaiting?.(false);
    }
  };
};

export function updateUserSettings(): ThunkAction<void> {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: updateUserSettings");

    try {
      const state = getState();
      const item = state.system.userSettings;

      if (item.itemState !== "updateNeeded" || item.updates.some(s => s.pending)) {
        log.error("updateUserSettings called when update is already in progress", { item });
        return;
      }

      const updates = mergeUpdates(item.updates);
      dispatch(userSettingsUpdateStarted());
      const resp = await deps.api.withThrow().updateUserSettings(updates);
      dispatch(userSettingsUpdateSucceeded(resp.data));
    } catch (err) {
      log.errorCaught("Error in updateUserSettings", err);
      dispatch(userSettingsUpdateErrored());
    }
  };
}

export function updateUserCheckpoints(): ThunkAction<void> {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: updateUserCheckpoints");

    try {
      const state = getState();
      const item = state.system.userCheckpoints;

      if (item.itemState !== "updateNeeded" || item.updates.some(s => s.pending)) {
        log.error("updateUserCheckpoints called when update is already in progress", { item });
        return;
      }

      const updates = mergeUpdates(item.updates);
      dispatch(checkpointsUpdateStarted());
      const resp = await deps.api.withThrow().updateUserCheckpoints(updates);
      dispatch(checkpointsUpdateSucceeded(resp.data));
    } catch (err) {
      log.errorCaught("Error in updateUserCheckpoints", err);
      dispatch(checkpointsUpdateErrored());
    }
  };
}

function invaldProfileArgsErrorToResult(payload: UserInvalidProfileArgsError["payload"]): CreateEditProfileResult {
  switch (payload.reason) {
    case "nameInvalid":
    case "usernameInvalid":
    case "usernameUnavailable":
    case "bioInvalid":
    case "linksInvalid":
      return payload.reason;
    default:
      bottomThrow(payload.reason);
  }
}

export const isUsernameAvailable = (
  username: string,
  setWaiting?: SetWaitingHandler
): ThunkAction<UsernameAvailableResult> => {
  return async (_dispatch, _getState, deps) => {
    log.info("Thunk: isUsernameAvailable");

    const res = await deps.api.withThrow(setWaiting).isUsernameAvailable({ username });
    return res.data;
  };
};

export const enableDiagnosticMode = (setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: enableDiagnosticMode");

    try {
      setWaiting?.(true);
      // this allows the spinner to start before things freeze because of the gzip
      await sleep(0);

      log.info("Thunk: enableDiagnosticMode");
      log.setRemoteLogLevel("info");
      const endTime = addHours(deps.time.epochMs(), 12);
      dispatch(diagnosticModeEnabled({ endTime }));

      const state = getState();
      const stringified = JSON.stringify(state);
      const base64GzippedState = await gzip(stringified);
      await deps.api.withThrow().storeAppState({ base64GzippedState });
    } catch (err) {
      log.errorCaught("Error in enableDiagnosticMode", err);
    } finally {
      setWaiting?.(false);
    }
  };
};

export const disableDiagnosticMode = (setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, _deps) => {
    log.info("Thunk: disableDiagnosticMode");
    setWaiting?.(true);
    dispatch(diagnosticModeDisabled());
    log.setRemoteLogLevel();
    setWaiting?.(false);
  };
};

export const isBetaInstall = (): SyncThunkAction<boolean> => {
  return (_dispatch, _getState, deps) => {
    return !!deps.appInfo?.isBetaInstall;
  };
};

export const getEncodedSupportInfo = (): ThunkAction<string> => {
  return async (_dispatch, getState, deps) => {
    log.info("Thunk: getEncodedSupportInfo");

    const state = getState();
    const mpid = await deps.mixpanel?.getDistinctId();
    const platform = Platform.OS;
    const uid = state.system.authedUser.data?.userId;
    const version = deps.appInfo?.version;
    const sha = CurrentEnvironment.gitSha();

    const o = {
      uid,
      mpid,
      platform,
      version,
      sha,
    };

    const str = JSON.stringify(o);
    return Buffer.from(str).toString("base64");
  };
};

export const reportIssue = (args: ReportIssueData, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: reportIssue");
    await deps.api.withThrow(setWaiting).reportIssue(args);

    let negativeInteraction = false;
    switch (args.type) {
      case "recipeIssue":
      case "socialMediaRecipeMissingIssue":
      case "userRecipeContentIssue":
      case "userFeedbackSubmitted":
        negativeInteraction = true;
        break;
      case "requestedEntityPage":
        break;
      default:
        bottomNop(args);
    }

    if (negativeInteraction) {
      dispatch(numericCheckpointsUpdated({ lastNegativeInteraction: defaultTimeProvider() }));
    }

    dispatch(analyticsEvent(reportIssueReported(args)));
  };
};

export const getDeviceType = (): SyncThunkAction<string | undefined> => {
  return (_dispatch, _getState, deps) => {
    return deps.deviceInfo.getDeviceType();
  };
};

export const getShowNewFeatureAlert = (feature: "scaling"): ThunkAction<boolean> => {
  return async (_dispatch, _gs, deps) => {
    try {
      const item = await deps.asyncStorage.getItem(getNewFeatureAlertFlagKey(feature));
      return !item;
    } catch (err) {
      log.errorCaught("getShowNewFeatureAlert() errored", err, { feature });
      return false;
    }
  };
};

export const setShowNewFeatureAlertDismissed = (feature: "scaling"): ThunkAction<void> => {
  return async (_dispatch, _gs, deps) => {
    try {
      await deps.asyncStorage.setItem(getNewFeatureAlertFlagKey(feature), feature);
    } catch (err) {
      log.errorCaught("setShowNewFeatureAlert() errored", err, { feature });
    }
  };
};

export const clearShowNewFeatureAlertDismissed = (feature: "scaling"): ThunkAction<void> => {
  return async (_dispatch, _gs, deps) => {
    try {
      await deps.asyncStorage.removeItem(getNewFeatureAlertFlagKey(feature));
    } catch (err) {
      log.errorCaught("clearShowNewFeatureAlert() errored", err, { feature });
    }
  };
};

function getNewFeatureAlertFlagKey(featureKey: "scaling"): string {
  return `${featureKey}_new_feature_alert_dismissed}`;
}

function isEmailSignInLinkForApp(url: string): boolean {
  // in the web sign-in flow, the initial link looks like this:
  // https://premium-botany-297107.firebaseapp.com/__/auth/action?apiKey=AIzaSyDxNaVA7KNB0Dn5tmk9DufOh5dx0zcyX-8&mode=signIn&oobCode=EVe3vLi8SRuFNJtXV9JfLDOf6MG9bFtT4-KjEEss-0kAAAGRXBzWyg&continueUrl=https://web.mooklab-dev.link/sign-in/email/check?email%3Djacob%2540deglaze.app&lang=en
  // Note that it's using the firebase domain so that the app is *not* opened
  // Firebase then redirects back to our email confirmation screen which redirects the user, so the link received looks like this:
  // DEEP LINK RECEIVED: https://web.mooklab-dev.link/sign-in/email/check?email=jacob%40deglaze.app&apiKey=AIzaSyDxNaVA7KNB0Dn5tmk9DufOh5dx0zcyX-8&oobCode=EVe3vLi8SRuFNJtXV9JfLDOf6MG9bFtT4-KjEEss-0kAAAGRXBzWyg&mode=signIn&lang=en
  // but note that the params which cause isAppSignInUrl are still present which is why we have the check for platform=web below.

  // in the app sign-in flow, the initial link looks like this:
  // https://web.mooklab-dev.link/?link=https://premium-botany-297107.firebaseapp.com/__/auth/action?apiKey%3DAIzaSyDxNaVA7KNB0Dn5tmk9DufOh5dx0zcyX-8%26mode%3DsignIn%26oobCode%3DiTVb1cZY2DymwClL6Qu_bFE7GCS-rOuunt5ddxqhCPgAAAGRXB3I_w%26continueUrl%3Dhttps://web.mooklab-dev.link/sign-in/email/check?email%253Djacob%252540deglaze.app%26lang%3Den&ibi=app.deglaze.dev&ifl=https://premium-botany-297107.firebaseapp.com/__/auth/action?apiKey%3DAIzaSyDxNaVA7KNB0Dn5tmk9DufOh5dx0zcyX-8%26mode%3DsignIn%26oobCode%3DiTVb1cZY2DymwClL6Qu_bFE7GCS-rOuunt5ddxqhCPgAAAGRXB3I_w%26continueUrl%3Dhttps://web.mooklab-dev.link/sign-in/email/check?email%253Djacob%252540deglaze.app%26lang%3Den
  // note the lack of any path

  const u = new URL(url);
  return u.pathname === "/";
}

function getCustomSchemeUrlAndEmail(url: string): { url: string; email?: string } {
  const receivedUrl = new URL(url);
  // this is a protocol we have configured for firebase auth. We added deglaze in 3.3, so we can update this in the future
  // to be deglaze://, which is less scary looking
  const appUrl = new URL("com.googleusercontent.apps.659129864238-ghtv8j1khs4u1lu37v373hn5p0h9cva0://app");
  appUrl.pathname = receivedUrl.pathname;
  appUrl.search = receivedUrl.search;
  appUrl.hash = receivedUrl.hash;

  let email: string | undefined;

  // make a best effort to parse email
  try {
    const continueUrl = getContinueUrlFromSignInLink(url);
    if (continueUrl) {
      const u = new URL(continueUrl);
      email = u.searchParams.get("email") ?? undefined;
    }
  } catch (err) {
    log.errorCaught("Couldn't parse email from sign-in link", err, { url });
  }

  return { url: appUrl.href, email };
}

function getContinueUrlFromSignInLink(url: string): string | undefined {
  try {
    const u = new URL(url);
    const linkParam = u.searchParams.get("link");

    if (linkParam) {
      const u2 = new URL(linkParam);
      const continueUrl = u2.searchParams.get("continueUrl");
      if (continueUrl) {
        log.info(`Parsed continueUrl from email sign-in link: ${continueUrl}`);
        return continueUrl;
      }
    }

    log.info(`Couldn't parse continueUrl from email sign-in link: ${url}`);
    return undefined;
  } catch (err) {
    log.errorCaught("Error parsing sign in link", err, { url });
    return undefined;
  }
}
