import {
  ReceivedServerData,
  ServerData,
  serverDataErrored,
  serverDataReceived,
  serverDataRequested,
} from "../redux/ServerData";
import { defaultTimeProvider, DurationMs, EpochMs, setDiff, TypedPrimitive, UserId } from "@eatbetter/common-shared";
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import {
  AppUserRecipe,
  UserRecipes,
  RecipesUpdatedData,
  UserRecipeId,
  RecipesScript,
  RecipeId,
} from "@eatbetter/recipes-shared";
import { appMetadataAvailable, newAppSession, userSignedInEvent, userSignedOutEvent } from "../system/SystemSlice";
import { Draft } from "immer";
import { EditUserRecipeOnSaveArgs } from "../../components/recipes/RecipeEditControl";
import { RecipeTag, RecipeTagManifest } from "@eatbetter/recipes-shared/dist/RecipeTagTypes";
import { log } from "../../Log";
import { deleteSingleInstanceFromArray } from "../redux/ReduxUtil.ts";

export interface RecipeStatus {
  version?: EpochMs;
  lastFullGet?: EpochMs;
}

export type RecipeTimeFilter = { total?: DurationMs };

interface RecipeTimeTagBase<TType extends string> {
  type: TType;
  tag: string;
}

export interface RecipeTotalTimeTag extends RecipeTimeTagBase<"totalTime"> {
  totalTime: RecipeTimeFilter["total"];
}

export type RecipeTimeTag = RecipeTotalTimeTag;

export type AppRecipeTag = RecipeTag | RecipeTimeTag;

export interface RecipeFilters {
  search?: string;
  tags?: RecipeTag[];
  time?: RecipeTimeTag[];
}

export type ReaderModeEnabledInstanceId = TypedPrimitive<string, "ReaderModeEnabledInstanceId">;

export interface RecipesState extends EntityState<AppUserRecipe, string> {
  meta: ServerData<{}> & RecipeStatus;
  sessionStart: EpochMs;
  lastViewsBeforeSession: Record<UserRecipeId, EpochMs>;
  /**
   * Keep track of recipes in the processing state - a reactor will periodically try to refresh them
   * in case websocket updates don't work. See updateProcessingRecipesReactor and updateProcessingLibraryRecipes thunk
   */
  processingRecipes: Record<UserRecipeId, EpochMs>;

  /**
   * User recipes currently being views in the app. We track these to make sure we don't blow away scaling info when we
   * get an update that does not have it.
   */
  activeRecipeIds: UserRecipeId[];

  userId?: UserId;
  tagManifest: RecipeTagManifest;
  filters: RecipeFilters;
  script: ServerData<RecipesScript>;

  // if the user is working on a recipe, save it in case they hit back, the app crashes, etc.
  newRecipeDraft?: EditUserRecipeOnSaveArgs;

  // Instance IDs of recipes with reader mode enabled
  readerModeEnabled: Record<ReaderModeEnabledInstanceId, RecipeId>;

  // tell the UI to highlight the add button on screen focus
  highlightLibraryAddButton?: boolean;
}

const recipesAdapter = createEntityAdapter<AppUserRecipe, string>({
  selectId: r => r.id,
  sortComparer: (a, b) => b.version - a.version,
});

const initialState: RecipesState = recipesAdapter.getInitialState({
  meta: {},
  sessionStart: 0 as EpochMs,
  lastViewsBeforeSession: {},
  processingRecipes: {},
  activeRecipeIds: [],
  filters: {},
  script: {},
  tagManifest: {
    tagDisplay: {},
    categoryList: [],
  },
  readerModeEnabled: {},
});

export function rehydrateRecipeState(persisted: Draft<RecipesState>): void {
  persisted.filters = {};
  persisted.activeRecipeIds = [];
}

const recipesSlice = createSlice({
  name: "recipes",
  initialState,

  reducers: create => ({
    recipesRequested: create.reducer((state, action: PayloadAction<EpochMs>) => {
      serverDataRequested(state.meta, action.payload);
    }),

    recipesPartialReceived: create.reducer((state, action: PayloadAction<ReceivedServerData<UserRecipes[]>>) => {
      const receivedRecipes = combineAndCleanPagedUserRecipes(action.payload.data);
      serverDataReceived(state.meta, {
        data: {},
        startTime: action.payload.startTime,
      });
      if (receivedRecipes.version > (state.meta.version ?? 0)) {
        state.meta.version = receivedRecipes.version;
        const recipesToUpdate = filterOutActiveRecipes(state, receivedRecipes.recipes);
        saveLastViewedTimes(state, recipesToUpdate);
        // this is much faster than individual calls
        recipesAdapter.setMany(state, recipesToUpdate);
        recipesToUpdate
          .filter(r => r.deleted)
          .forEach(r => {
            recipesAdapter.removeOne(state, r.id);
          });
        receivedRecipes.recipes.forEach(r => addProcessingIfNotPresent(state, r));
      }
    }),

    recipesFullReceived: create.reducer((state, action: PayloadAction<ReceivedServerData<UserRecipes[]>>) => {
      const receivedRecipes = combineAndCleanPagedUserRecipes(action.payload.data);
      serverDataReceived(state.meta, {
        data: {},
        startTime: action.payload.startTime,
      });

      if (receivedRecipes.version > (state.meta.version ?? 0)) {
        state.meta.version = receivedRecipes.version;
        state.meta.lastFullGet = action.payload.startTime;

        const inactiveRecipes = filterOutActiveRecipes(state, receivedRecipes.recipes);
        saveLastViewedTimes(state, inactiveRecipes);

        recipesAdapter.setMany(
          state,
          inactiveRecipes.filter(r => !r.deleted)
        );
        inactiveRecipes.forEach(r => addProcessingIfNotPresent(state, r));
        const newKeys = receivedRecipes.recipes.map(r => r.id);
        const diff = setDiff(state.ids, newKeys);

        // we need to remove recipes that are no longer represented on the server,
        // but make sure they aren't currently being viewed (this should really not happen).
        diff.removed.forEach(r => {
          if (state.activeRecipeIds.includes(r as UserRecipeId)) {
            log.error(`Recipe ${r} is currently being viewed but is not represented in full recipe list results`);
          } else {
            recipesAdapter.removeOne(state, r);
          }
        });
      }
    }),

    recipesErrored: create.reducer(state => {
      serverDataErrored(state.meta);
    }),

    recipeAdded: create.reducer((state, action: PayloadAction<AppUserRecipe>) => {
      saveLastViewedTime(state, action.payload.id);
      recipesAdapter.addOne(state, action.payload);
      addProcessingIfNotPresent(state, action.payload);
    }),

    recipeEdited: create.reducer((state, action: PayloadAction<AppUserRecipe>) => {
      updateSingleRecipe(state, action.payload);
    }),

    recipesUpdatedPushReceived: create.reducer((state, action: PayloadAction<RecipesUpdatedData>) => {
      action.payload.recipes.forEach(r => {
        updateSingleRecipe(state, r);
      });
    }),

    singleRecipeReceived: create.reducer((state, action: PayloadAction<AppUserRecipe>) => {
      updateSingleRecipe(state, action.payload);
    }),

    saveNewRecipeDraft: create.reducer((state, action: PayloadAction<EditUserRecipeOnSaveArgs | undefined>) => {
      state.newRecipeDraft = action.payload;
    }),

    setReaderMode: create.reducer(
      (
        state,
        action: PayloadAction<{ instanceId: ReaderModeEnabledInstanceId; recipeId: RecipeId; isEnabled: boolean }>
      ) => {
        if (action.payload.isEnabled) {
          state.readerModeEnabled[action.payload.instanceId] = action.payload.recipeId;
        } else {
          delete state.readerModeEnabled[action.payload.instanceId];
        }
      }
    ),

    setSearchFilter: create.reducer((state, action: PayloadAction<string>) => {
      state.filters.search = action.payload;
    }),

    addTagToLibraryFilter: create.reducer((state, action: PayloadAction<AppRecipeTag>) => {
      const tag = action.payload;

      const updateTimeFilter = (type: Extract<RecipeTimeTag["type"], "totalTime" | "activeTime">) => {
        if (tag.type === type) {
          if (!state.filters.time) {
            state.filters.time = [];
          }

          const existingTimeIndex = state.filters.time.findIndex(i => i.type === type);
          if (existingTimeIndex >= 0) {
            state.filters.time[existingTimeIndex] = tag;
            return;
          }

          state.filters.time.push(tag);
        }
      };

      if (tag.type === "totalTime") {
        updateTimeFilter("totalTime");
        return;
      }

      if (!state.filters.tags) {
        state.filters.tags = [];
      }

      const existingIndex = state.filters.tags.findIndex(ft => ft.tag === tag.tag && ft.type === tag.type);
      if (existingIndex >= 0) {
        return;
      }

      state.filters.tags.push(tag);
    }),

    removeTagFromLibraryFilter: create.reducer((state, action: PayloadAction<AppRecipeTag>) => {
      const tag = action.payload;

      if (tag.type === "totalTime") {
        const currentTimeTags = state.filters.time ?? [];
        const updatedTimeTags = currentTimeTags.filter(i => i.tag !== tag.tag || i.type !== tag.type);
        state.filters.time = updatedTimeTags;
      }

      const current = state.filters.tags ?? [];
      const updated = current.filter(t => t.tag !== tag.tag || t.type !== tag.type);
      state.filters.tags = updated;
    }),

    removeAllLibraryFilters: create.reducer(state => {
      state.filters.search = "";
      state.filters.time = [];
      state.filters.tags = [];
    }),

    recipesScriptRequested: create.reducer((state, action: PayloadAction<EpochMs>) => {
      serverDataRequested(state.script, action.payload);
    }),

    recipesScriptReceived: create.reducer((state, action: PayloadAction<ReceivedServerData<RecipesScript>>) => {
      serverDataReceived(state.script, action.payload);
    }),

    recipesScriptErrored: create.reducer(state => {
      serverDataErrored(state.script);
    }),

    highlightLibraryAddButtonChanged: create.reducer((state, action: PayloadAction<boolean>) => {
      state.highlightLibraryAddButton = action.payload;
    }),

    libraryRecipeFetchStarted: create.reducer((state, action: PayloadAction<UserRecipeId>) => {
      if (state.processingRecipes.hasOwnProperty(action.payload)) {
        state.processingRecipes[action.payload] = defaultTimeProvider();
      }
    }),

    recipesNoLongerProcessing: create.reducer((state, action: PayloadAction<UserRecipeId[]>) => {
      action.payload.forEach(id => delete state.processingRecipes[id]);
    }),

    recipeDetailMounted: create.reducer((state, action: PayloadAction<UserRecipeId>) => {
      state.activeRecipeIds.push(action.payload);
    }),

    recipeDetailUnmounted: create.reducer((state, action: PayloadAction<UserRecipeId>) => {
      // it's possible to have the same recipe mounted on multiple screens, so we only remove a single instance
      deleteSingleInstanceFromArray(action.payload, state.activeRecipeIds);
    }),
  }),

  extraReducers: builder => {
    builder.addCase(newAppSession, (state, action) => {
      state.sessionStart = action.payload.startTime;
      state.lastViewsBeforeSession = {};
    });

    builder.addCase(userSignedInEvent, (state, action) => {
      state.userId = action.payload.userId;
    });

    builder.addCase(userSignedOutEvent, state => {
      state.userId = undefined;
    });

    builder.addCase(appMetadataAvailable, (state, action) => {
      state.tagManifest = action.payload.recipes.tagManifest;
    });
  },
});

function filterOutActiveRecipes(state: RecipesState, recipes: AppUserRecipe[]): AppUserRecipe[] {
  const active = new Set(state.activeRecipeIds);
  const filtered = recipes.filter(r => !active.has(r.id));
  log.info(`Found ${recipes.length - filtered.length} active recipes in partial/full update. Filtering out.`);
  return filtered;
}

function updateSingleRecipe(state: Draft<RecipesState>, recipe: AppUserRecipe): void {
  const existing = state.entities[recipe.id];

  // update if versions match to allow for server bugs in construction of the recipe
  if (existing && existing.version > recipe.version) {
    log.info(`Got a stale update in updateSingleRecipe for recipe ${recipe.id}`, {
      recipeId: recipe.id,
      staleVersion: recipe.version,
      existingVersion: existing.version,
    });
    return;
  }

  // if a recipe is being viewed, and we get an update from loadRecipes, the list of recipes
  // won't include scaling info. So, we keep a list of recipes that are currently being viewed
  // and we make sure we don't blow away scaling info for these recipes.
  if (!recipe.hasScalingInfo && state.activeRecipeIds.includes(recipe.id)) {
    log.info(`Skipping update for ${recipe.id} because it's mounted and update has no scaling info`);
    return;
  }

  if (recipe.deleted) {
    recipesAdapter.removeOne(state, recipe.id);
  } else {
    saveLastViewedTime(state, recipe.id);
    recipesAdapter.setOne(state, recipe);
    addProcessingIfNotPresent(state, recipe);
  }
}

// we add to this object on the way in. We delete from it in the reactor to keep the touchpoints here a bit simpler
// and it is (maybe only marginally) more efficient.
function addProcessingIfNotPresent(state: Draft<RecipesState>, recipe: AppUserRecipe) {
  if (recipe.status === "processing" && !state.processingRecipes.hasOwnProperty(recipe.id)) {
    state.processingRecipes[recipe.id] = recipe.version;
  }
}

function combineAndCleanPagedUserRecipes(results: UserRecipes[]): UserRecipes {
  if (results.length === 1) {
    return results[0]!;
  }

  const userRecipes = results.reduce((a, b) => {
    return {
      version: Math.max(a.version, b.version) as EpochMs,
      recipes: [...a.recipes, ...b.recipes],
      recipesFor: a.recipesFor,
    };
  });

  // since we have multiple calls, it's theoretically possible to have multiple versions of the same recipe (if the recipe was
  // edited in the time frame between the 2 calls). Remove the older version. In nearly all cases, this will be a nop
  // sort ascending - it *should* already be sorted this way, but just in case...
  const sorted = userRecipes.recipes.sort((a, b) => a.version - b.version);
  const seen = new Set<RecipeId>();
  const recipes: AppUserRecipe[] = [];

  // work backwards since are sorted by version ascending. The first version of a recipe we encounter is the newest
  // and any others we see can be discarded
  for (let i = sorted.length - 1; i >= 0; i--) {
    const r = sorted[i]!;
    if (seen.has(r.id)) {
      log.logRemote(`Got dup recipe ${r.id} while loading user recipes`);
    } else {
      seen.add(r.id);
      recipes.push(r);
    }
  }

  return {
    ...userRecipes,
    recipes,
  };
}

function saveLastViewedTimes(state: Draft<RecipesState>, recipes: AppUserRecipe[]): void {
  recipes.forEach(r => {
    saveLastViewedTime(state, r.id);
  });
}

// we don't update the order of recipes based on view until a new "session" is started.
// this prevents the order of the recipe list from changing as a user views recipes in the list
// so, before we update a recipe, we save the last viewed time in redux and use that saved time
// until a new session starts, at which point we blow away that date
function saveLastViewedTime(state: Draft<RecipesState>, recipeId: UserRecipeId): void {
  const r = state.entities[recipeId];
  if (state.userId && r) {
    // if the user is viewing the recipe for the first time, we need to save the value
    // that is used to sort the recipe. If there is no view time, then it's the lastAction time.
    const lastViewedOrAction = r.stats.lastViewed[state.userId] ?? r.stats.lastAction;
    const previouslySaved = state.lastViewsBeforeSession[recipeId];
    if (lastViewedOrAction !== undefined && !previouslySaved) {
      state.lastViewsBeforeSession[recipeId] = lastViewedOrAction;
    }
  }
}

export const {
  recipeAdded,
  recipeEdited,
  recipesRequested,
  recipesPartialReceived,
  recipesFullReceived,
  recipesErrored,
  recipesUpdatedPushReceived,
  saveNewRecipeDraft,
  setSearchFilter,
  setReaderMode,
  addTagToLibraryFilter,
  removeTagFromLibraryFilter,
  removeAllLibraryFilters,
  singleRecipeReceived,
  recipesScriptRequested,
  recipesScriptReceived,
  recipesScriptErrored,
  highlightLibraryAddButtonChanged,
  recipesNoLongerProcessing,
  libraryRecipeFetchStarted,
  recipeDetailMounted,
  recipeDetailUnmounted,
} = recipesSlice.actions;

export const recipesReducer = recipesSlice.reducer;

export const recipeAdapterSelectors = recipesAdapter.getSelectors();
