import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResponsiveDimensions } from "./Responsive";
import { RootSiblingPortal } from "react-native-root-siblings";
import { StyleSheet, View } from "react-native";
import { Pressable } from "./Pressable";
import Animated, { SlideInDown } from "react-native-reanimated";
import { NewBadge } from "./Badges";
import { Spacer } from "./Spacer";
import { TBody, TSecondary } from "./Typography";
import { ButtonRectangle } from "./Buttons";
import { globalStyleColors, globalStyleConstants, globalStyles, Opacity } from "./GlobalStyles";
import { Video, VideoProps } from "./Video";
import { useScreen } from "../navigation/ScreenContainer";
import { useDispatch } from "../lib/redux/Redux";
import { getShowNewFeatureAlert, setShowNewFeatureAlertDismissed } from "../lib/system/SystemThunks";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportNewFeatureModalDismissed, reportNewFeatureModalDisplayed } from "../lib/analytics/AnalyticsEvents";

const newFeatureVideo = require("../assets/new_features/scaling.mp4");

export function useNewFeatureModal(): React.ReactNode {
  const dispatch = useDispatch();
  const screen = useScreen();

  const [showNewFeatureFlag, setShowNewFeatureFlag] = useState(false);
  // Separate state for conditional rendering of the modal so that we can control/tweak the entry/exit
  const [showNewFeatureModal, setShowNewFeatureModal] = useState(false);

  // Load value from async storage to determine if we want to show the new feature modal
  // Note: we set the async storage key during onboarding (`OnboardingStartScreen.tsx`) to ensure that
  // this only gets shown to existing users.
  useEffect(() => {
    dispatch(getShowNewFeatureAlert("scaling"))
      .then(value => setShowNewFeatureFlag(value))
      .catch(() => {
        // thunk handles errors
      });
  }, []);

  // Shows/hides the modal
  useEffect(() => {
    if (showNewFeatureFlag) {
      if (screen.nav.focused) {
        // Slight delay for aesthetic purposes
        setTimeout(() => {
          dispatch(analyticsEvent(reportNewFeatureModalDisplayed("scaling")));
          setShowNewFeatureModal(true);
        }, 300);
      }
    } else {
      setShowNewFeatureModal(false);
    }
  }, [showNewFeatureFlag]);

  const onDismiss = useCallback(async () => {
    // Create file in async storage to suppress showing again and update local state to hide modal
    await dispatch(setShowNewFeatureAlertDismissed("scaling"));
    setShowNewFeatureFlag(false);
    dispatch(analyticsEvent(reportNewFeatureModalDismissed("scaling")));
  }, []);

  const modalProps: NewFeatureModalProps = {
    headline: "Scaling & Unit Conversions",
    subheadline: "Now you can scale and convert units on any recipe.",
    video: {
      videoAnalyticsId: "scalingNewFeatureDemo",
      videoSource: { type: "bundled", nodeRequireOutput: newFeatureVideo },
      aspectRatio: 300 / 200,
    },
    onDismiss,
  };

  return useMemo(() => showNewFeatureModal && <NewFeatureModal {...modalProps} />, [showNewFeatureModal, modalProps]);
}

const strings = {
  gotIt: "Got it",
};

interface NewFeatureModalProps {
  headline: string;
  subheadline: string;
  video?: Pick<VideoProps, "videoAnalyticsId" | "videoSource"> & { aspectRatio: number };
  onDismiss: () => void;
}

const NewFeatureModal = React.memo((props: NewFeatureModalProps) => {
  const dimensions = useResponsiveDimensions();
  const messageWidth = Math.min(350, 0.75 * dimensions.width);

  return (
    <RootSiblingPortal>
      <View style={styles.modal}>
        <Pressable style={styles.background} onPress={props.onDismiss} />
        <Animated.View entering={SlideInDown.delay(200)} style={[styles.messageContainer, { width: messageWidth }]}>
          <NewBadge />
          <Spacer vertical={1.5} />
          <TBody fontWeight="medium" align="center">
            {props.headline}
          </TBody>
          <Spacer vertical={0.5} />
          <TSecondary align="center">{props.subheadline}</TSecondary>
          {!!props.video && (
            <>
              <Spacer vertical={1} />
              <View style={{ width: "100%", aspectRatio: props.video.aspectRatio }}>
                <Video
                  videoAnalyticsId={props.video.videoAnalyticsId}
                  videoSource={props.video.videoSource}
                  autoPlay
                  isLooping
                  playbackMode={{ type: "contained" }}
                  resizeMode="cover"
                  hidePlayButton
                  backgroundColor="transparent"
                  spinnerColor="dark"
                  tapToExpand={false}
                />
              </View>
            </>
          )}
          <Spacer vertical={1.5} />
          <GotItButton onPress={props.onDismiss} />
        </Animated.View>
      </View>
    </RootSiblingPortal>
  );
});

const GotItButton = React.memo((props: { onPress: () => void }) => {
  return (
    <View style={{ width: 48 }}>
      <ButtonRectangle type="secondary" title={strings.gotIt} size="small" onPress={props.onPress} />
    </View>
  );
});

const styles = StyleSheet.create({
  modal: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
  messageContainer: {
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    padding: globalStyleConstants.defaultPadding,
    borderRadius: globalStyleConstants.defaultBorderRadius,
    ...globalStyles.shadowItem,
    shadowOpacity: Opacity.medium,
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: globalStyleColors.rgba("black", "medium"),
  },
});
