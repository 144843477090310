import { bottomThrow, range, switchReturn } from "@eatbetter/common-shared";
import { AppRecipe, AppRecipeBase, AppUserRecipe } from "@eatbetter/recipes-shared";
import React, { useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { ScrollViewProps, StyleSheet, View } from "react-native";
import { TSecondary } from "../Typography";
import { tabViewConstants, TabView, TabViewRoute, TabViewBarProps } from "../TabView";
import { PropsWithChildren } from "react";
import {
  WebViewNavBar,
  WebView,
  webViewConstants,
  WebViewImperativeHandle,
  WebViewNavigationStateChangeHandler,
  WebViewScrollEventHandler,
  WebViewLoadingProgressBar,
} from "../WebView";
import { smallScreenBreakpoint, useResponsiveDimensions } from "../Responsive";
import Animated, {
  AnimatedRef,
  Extrapolation,
  interpolate,
  scrollTo,
  SharedValue,
  useAnimatedReaction,
  useAnimatedRef,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { ContainerFadeIn } from "../Containers";
import { useMemo } from "react";
import { RecipeDetailContent } from "./RecipeDetailContent";
import { log } from "../../Log";
import { BottomNotch } from "../BottomNotch";
import { useBottomTabBarDimensions } from "../../navigation/TabBar";
import { useAnimatedScreenHeaderDimensions } from "../ScreenHeaders";
import { useScreenElementDimensions } from "../ScreenView";
import { Opacity, globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { useDispatch } from "../../lib/redux/Redux";
import { reportRecipeDetailTabChanged } from "../../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { Separator } from "../Separator";
import { useShowPaywallStatusPill } from "../../lib/debug/DebugSelectors";
import { usePaywallStatus } from "../PaywallDetector";
import { useRecipeSourceUrl } from "../../lib/recipes/RecipesSelectors";
import { useWebViewIsNavigated, useWebViewLoadingProgress } from "../../lib/webview/WebViewSelectors";
import { WebViewSessionId } from "../../lib/webview/WebViewSlice";
import { getPullToRefresh } from "../PullToRefresh";

/**
 * Constants
 */

const config = {
  scrollReactionDelay: 7,
};

const constants = {
  tabViewBarHeight: tabViewConstants.tabBarHeight,
  webNavBarHeight: webViewConstants.webNavBarHeight,
};

const strings = {
  tabMenu: {
    recipeInfo: "Recipe",
    notes: "Notes",
    ingredients: "Ingredients",
    instructions: "Instructions",
    ingredientsAndInstructions: "Ingredients + Instructions",
  },
  toast: {
    webViewErrored: "Offline mode: could not load web recipe",
  },
};

/**
 * Types
 */

type TabMenuItem = "recipeInfo" | "notes" | "ingredients" | "instructions";
type TabRouteProps = { recipe: AppRecipeBase };
type ScrollEventHandler = ReturnType<typeof useAnimatedScrollHandler>;

/**
 * Props
 */

export interface RecipeDetailProps {
  recipe: AppRecipe;
  webViewSessionId?: WebViewSessionId;
  renderActionBar?: () => React.ReactNode;
  actionBarHeight?: number;
  isReaderMode?: boolean;
  toggleReaderMode?: () => void;
  renderIngredients?: (recipe: AppRecipeBase) => React.ReactNode;
  renderInstructions?: (recipe: AppRecipeBase) => React.ReactNode;
  renderNotes?: (recipe: AppUserRecipe) => React.ReactNode;
  haveUnreadNotes?: boolean;
  onViewNotes?: () => void;
  screenHeaderAnimationRef?: SharedValue<number>;
  onWebViewNavigationStateChange?: WebViewNavigationStateChangeHandler;
  refreshLibraryRecipe?: () => Promise<void>;
  recipeScale: number;
  setRecipeScale?: (v: number) => void;
  waitingFetchScalingData?: boolean;
  fetchScalingDataErrored?: boolean;
}

/**
 * Hooks
 */

const useAnimatedTab = (): [
  AnimatedRef<Animated.ScrollView>,
  SharedValue<number>,
  SharedValue<number>,
  ScrollEventHandler
] => {
  const ref = useAnimatedRef<Animated.ScrollView>();
  const scrollPosition = useSharedValue(0);
  const scrollContentHeight = useSharedValue(0);

  const scrollHandler = useAnimatedScrollHandler(
    {
      onScroll: e => {
        scrollPosition.value = e.contentOffset.y;
        scrollContentHeight.value = e.contentSize.height;
      },
    },
    [scrollPosition, scrollContentHeight]
  );

  return [ref, scrollPosition, scrollContentHeight, scrollHandler];
};

const useWebViewTab = (
  scrollPosition: Required<SharedValue<number>>,
  scrollContentHeight: Required<SharedValue<number>>
): [
  ref: React.RefObject<WebViewImperativeHandle>,
  scrollHandler: WebViewScrollEventHandler,
  goBack: () => void,
  goForward: () => void,
  refresh: () => void
] => {
  const webViewRef = useRef<WebViewImperativeHandle>(null);

  // Web View does not support native driver, so we need a regular callback in the web view case
  const webViewScrollHandler = useCallback<NonNullable<WebViewScrollEventHandler>>(
    ({ nativeEvent: e }) => {
      scrollPosition.value = e.contentOffset.y + e.contentInset.top;
      scrollContentHeight.value = e.contentSize.height;
    },
    [scrollPosition, scrollContentHeight]
  );

  const onPressGoBack = useCallback(() => {
    webViewRef.current?.goBack();
  }, [webViewRef]);

  const onPressGoForward = useCallback(() => {
    webViewRef.current?.goForward();
  }, [webViewRef]);

  const onPressRefresh = useCallback(() => {
    webViewRef.current?.reload();
  }, [webViewRef]);

  return [webViewRef, webViewScrollHandler, onPressGoBack, onPressGoForward, onPressRefresh];
};

/**
 * Components
 */

export interface RecipeDetailHandle {
  switchTab: (tab: TabMenuItem, scrollY?: number) => void;
}

export const RecipeDetail = React.memo(
  React.forwardRef<RecipeDetailHandle, RecipeDetailProps>((props, ref) => {
    const dispatch = useDispatch();

    const [ingredientsRef, ingredientsScrollPosition, ingredientsScrollContentHeight, ingredientsScrollHandler] =
      useAnimatedTab();
    const [instructionsRef, instructionsScrollPosition, instructionsScrollContentHeight, instructionsScrollHandler] =
      useAnimatedTab();
    const [recipeInfoRef, recipeInfoScrollPosition, recipeInfoScrollContentHeight, recipeInfoScrollHandler] =
      useAnimatedTab();
    const [notesRef, notesScrollPosition, notesScrollContentHeight, notesScrollHandler] = useAnimatedTab();
    const [webViewRef, webViewScrollHandler, onPressGoBack, onPressGoForward, onPressRefresh] = useWebViewTab(
      recipeInfoScrollPosition,
      recipeInfoScrollContentHeight
    );

    const webViewIsNavigated = useWebViewIsNavigated(props.webViewSessionId);
    const webViewLoadingProgress = useWebViewLoadingProgress(props.webViewSessionId);

    const isReaderMode = props.isReaderMode;

    const dimensions = useResponsiveDimensions();
    const { bottomTabBarHeight } = useBottomTabBarDimensions();
    const { bareHeaderHeight } = useScreenElementDimensions();
    const { statusBarHeight, headerHeight: screenHeaderHeight } = useAnimatedScreenHeaderDimensions();
    const bottomActionBarHeight = props.actionBarHeight ?? 0;

    // On screens larger than a phone and that are in landscape, show ingredients and instructions side by side
    const showSideBySideInstructions = dimensions.width > smallScreenBreakpoint && dimensions.width > dimensions.height;

    /**
     * Supported tab routes and display logic for each tab title. `tabViewRoutes` (defined below) handles conditional rendering.
     * The length of the route array must be the same across re-renders to ensure that animation hook execution is stable.
     */
    const routes = useMemo<
      [TabViewRoute<"ingredients">, TabViewRoute<"instructions">, TabViewRoute<"recipeInfo">, TabViewRoute<"notes">]
    >(() => {
      return [
        {
          key: "ingredients",
          title: showSideBySideInstructions ? strings.tabMenu.ingredientsAndInstructions : strings.tabMenu.ingredients,
        },
        {
          key: "instructions",
          title: strings.tabMenu.instructions,
        },
        {
          key: "recipeInfo",
          title: strings.tabMenu.recipeInfo,
        },
        {
          key: "notes",
          title: strings.tabMenu.notes,
        },
      ];
    }, [showSideBySideInstructions]);

    /**
     * Controls which tabs to render
     */
    const displayedRoutes = useMemo(() => {
      const result: TabViewRoute<TabMenuItem>[] = [];

      if (props.renderIngredients) {
        const ingredientsTab = routes.find(i => i.key === "ingredients");
        if (ingredientsTab) {
          result.push(ingredientsTab);
        }
      }

      if (props.renderInstructions && !showSideBySideInstructions) {
        const instructionsTab = routes.find(i => i.key === "instructions");
        if (instructionsTab) {
          result.push(instructionsTab);
        }
      }

      const recipeInfoTab = routes.find(i => i.key === "recipeInfo");
      if (recipeInfoTab) {
        result.push(recipeInfoTab);
      }

      if (props.renderNotes) {
        const notesTab = routes.find(i => i.key === "notes");
        if (notesTab) {
          result.push(notesTab);
        }
      }

      return result;
    }, [routes, props.renderIngredients, props.renderInstructions, props.renderNotes, showSideBySideInstructions]);

    /**
     * Tracks the scroll position for each tab
     */
    const scrollPositions = useMemo(
      () =>
        routes.map(route => {
          switch (route.key) {
            case "ingredients":
              return {
                key: route.key,
                ref: ingredientsRef,
                scrollPosition: ingredientsScrollPosition,
                scrollContentHeight: ingredientsScrollContentHeight,
                isWebView: false,
              };
            case "instructions":
              return {
                key: route.key,
                ref: instructionsRef,
                scrollPosition: instructionsScrollPosition,
                scrollContentHeight: instructionsScrollContentHeight,
                isWebView: false,
              };
            case "recipeInfo":
              return {
                key: route.key,
                ref: recipeInfoRef,
                scrollPosition: recipeInfoScrollPosition,
                scrollContentHeight: recipeInfoScrollContentHeight,
                isWebView: !!props.webViewSessionId && !isReaderMode,
              };
            case "notes":
              return {
                key: route.key,
                ref: notesRef,
                scrollPosition: notesScrollPosition,
                scrollContentHeight: notesScrollContentHeight,
                isWebView: false,
              };
            default:
              bottomThrow(route, log);
          }
        }),
      [
        ingredientsRef,
        ingredientsScrollPosition,
        ingredientsScrollContentHeight,
        instructionsRef,
        instructionsScrollPosition,
        instructionsScrollContentHeight,
        recipeInfoRef,
        recipeInfoScrollPosition,
        recipeInfoScrollContentHeight,
        routes,
        props.webViewSessionId,
        isReaderMode,
        notesRef,
        notesScrollPosition,
        notesScrollContentHeight,
      ]
    );

    /**
     * Tracks the index of the focused tab in the set of currently displayed tabs, which can change dynamically (e.g. iPad landscape mode)
     */
    const [displayedRouteIndex, setDisplayedRouteIndex] = useState(0);

    /**
     * Tracks the index of the tab in the static set of all tracked tabs, including those not currently displayed
     */
    const routeIndex = routes.findIndex(i => {
      if (displayedRouteIndex < displayedRoutes.length) {
        return i.key === displayedRoutes[displayedRouteIndex]?.key;
      }
      return i.key === displayedRoutes.at(-1)?.key;
    });

    /**
     * Tracks the scroll position for the currently active route
     */
    const currentScrollPosition = scrollPositions[routeIndex];
    if (!currentScrollPosition) {
      // This should never happen and means we're in an unknown state that will result in unexpected behavior downstream
      throw new Error(
        `RecipeDetail.currentScrollPosition is falsy (unexpected state). { index: ${routeIndex}, scrollPositionsLength: ${scrollPositions.length} }`
      );
    }

    // If there's only one route, we don't show a tab bar (since there's nothing to switch to)
    const tabBarHeight = displayedRoutes.length > 1 ? constants.tabViewBarHeight : 0;

    // Scroll to top when reader mode is toggled on the native side, so that web view scrolling doesn't
    // leave the reader mode recipe in a weird place. This does not affect the web view scroll position
    // which is probably the desired behavior.
    useEffect(() => {
      recipeInfoRef.current?.scrollTo({ y: 0, animated: false });
    }, [props.isReaderMode]);

    // Pause scroll reactions until after the web view is done loading to avoid header/footer hiding for URLs with
    // a hash id. The web view auto scrolls to that part of the page (desired behavior), but we don't want that to
    // hide the header/footer.
    // When we programatically scroll, we also pause the reactions so it doesn't affect the header/footer positions.
    // Only do this when the default route (first one) is a web view.
    const pauseScrollReactions = useSharedValue(
      showSideBySideInstructions || (!!props.webViewSessionId && displayedRoutes[0]?.key === "recipeInfo")
    );

    // Enable scroll reactions (which are disabled by default on first render) once the web view has loaded
    useEffect(() => {
      if (webViewLoadingProgress === 1) {
        setTimeout(() => {
          pauseScrollReactions.value = showSideBySideInstructions;
        }, 500);
      }
    }, [webViewLoadingProgress]);

    // Make adjustments when switching to side-by-side ingredients + instructions view
    useEffect(() => {
      pauseScrollReactions.value = showSideBySideInstructions;
      setTimeout(() => setDisplayedRouteIndex(0), 300);
    }, [showSideBySideInstructions]);

    const webNavBarHeight = currentScrollPosition.isWebView && webViewIsNavigated ? constants.webNavBarHeight : 0;
    const footerTranslateY = useSharedValue(0);

    const isHeaderHidden = useCallback(() => {
      return props.screenHeaderAnimationRef?.value === screenHeaderHeight.value;
    }, [props.screenHeaderAnimationRef, screenHeaderHeight]);

    const currentTabIsWebView = currentScrollPosition.isWebView;

    /**
     * Controls header and footer show/hide on scroll up/down and adjusts scroll position across tabs to avoid header/footer invalid states
     */
    useAnimatedReaction(
      () => {
        return {
          scrollPosition: currentScrollPosition.scrollPosition.value,
          scrollContentHeight: currentScrollPosition.scrollContentHeight.value,
          tabIsWebView: currentScrollPosition.isWebView,
          routeIndex,
          pauseScrollReactions: pauseScrollReactions.value,
        };
      },
      (curr, prev) => {
        if (!prev) {
          return;
        }

        const prevScrollPosition = prev.scrollPosition;
        const currScrollPosition = curr.scrollPosition;

        const scrollContentHeight = curr.scrollContentHeight;

        const prevRouteIndex = prev.routeIndex;
        const currRouteIndex = curr.routeIndex;

        const currTabIsWebView = curr.tabIsWebView;

        const pauseScrollReactions = curr.pauseScrollReactions;

        // This is the height of the whitespace at the top of the screen behind the screen header + tabBar.
        // This "threshold" is used to ensure we never expose that whitespace, and that scroll / header states look smooth
        // and consistent when we switch tabs (see below for examples).
        const scrollTopThreshold = bareHeaderHeight + tabBarHeight;

        const hideScreenHeader = () => {
          if (!props.screenHeaderAnimationRef) {
            return;
          }
          props.screenHeaderAnimationRef.value = withTiming(screenHeaderHeight.value);
        };

        const showScreenHeader = () => {
          if (!props.screenHeaderAnimationRef) {
            return;
          }
          props.screenHeaderAnimationRef.value = withTiming(0);
        };

        const hideFooter = () => {
          footerTranslateY.value = withTiming(bottomActionBarHeight + bottomTabBarHeight + webNavBarHeight);
        };

        const showFooter = () => {
          footerTranslateY.value = withTiming(0);
        };

        /**
         * Show screen header + tab bar + footer syncrhonously on the UI thread. Adjust other tabs' scroll positions if they are
         * below the specified scroll position threshold.
         */
        const showHeaderAndFooter = () => {
          showFooter();
          if (props.screenHeaderAnimationRef) {
            showScreenHeader();
          }

          // Adjust scroll positions in other tabs if necessary
          scrollPositions.forEach(({ ref, scrollPosition, isWebView }, idx) => {
            if (idx === currRouteIndex || isWebView) {
              // WebView does not support scrollTo
              return;
            }

            if (scrollPosition.value <= scrollTopThreshold) {
              // Scroll to top if the scroll position is within the top threshold
              scrollTo(ref, 0, 0, false);
            }
          });
        };

        /**
         * Hide screen header + tab bar + footer syncrhonously on the UI thread. Adjust other tabs' scroll positions if they are
         * below the specified scroll position threshold.
         */
        const hideHeaderAndFooter = () => {
          hideFooter();
          if (props.screenHeaderAnimationRef) {
            hideScreenHeader();
          }

          // Adjust scroll positions in other tabs if necessary
          scrollPositions.forEach(({ ref, scrollPosition, isWebView }, idx) => {
            if (idx === currRouteIndex || isWebView) {
              // WebView does not support scrollTo
              return;
            }

            if (scrollPosition.value <= scrollTopThreshold) {
              // Scroll just past the threshold if the scroll position is within the top threshold
              scrollTo(ref, 0, scrollTopThreshold, false);
            }
          });
        };

        /** TAB CHANGE REACTIONS */

        if (prevRouteIndex !== currRouteIndex) {
          if (currTabIsWebView) {
            // Since we can't scroll WebViews, make sure that we show the header if the scroll position is below the threshold to avoid showing
            // whitespace at top of screen. This happens when the previous tab retracted the header, but the WebView scroll position is 0 or below
            // the threshold.
            if (currScrollPosition <= scrollTopThreshold) {
              showHeaderAndFooter();
            }
          }

          // Don't do anything else on tab switch
          return;
        }

        /** SCROLL POSITION REACTIONS */

        if (pauseScrollReactions) {
          return;
        }

        if (currScrollPosition === prevScrollPosition) {
          return;
        }

        // Prevent whitespace behind header from showing by showing header in those cases
        if (currScrollPosition < scrollTopThreshold) {
          showHeaderAndFooter();
          return;
        }

        // Scrolling up
        if (currScrollPosition > prevScrollPosition + config.scrollReactionDelay) {
          // Don't hide header/footer unless the scroll value is greater than the height of the header
          // to avoid showing the whitespace behind
          if (currScrollPosition > scrollTopThreshold) {
            hideHeaderAndFooter();
          }

          return;
        }

        // Scrolling down
        if (currScrollPosition < prevScrollPosition - config.scrollReactionDelay) {
          // console.log(`----- TEST scrollPosition ${currScrollPosition}, contentHeight: ${scrollContentHeight}`);
          if (currScrollPosition > scrollContentHeight) {
            return;
          }
          showHeaderAndFooter();
        }
      }
    );

    const animatedFooterStyle = useAnimatedStyle(() => {
      return {
        transform: [
          {
            translateY: footerTranslateY.value,
          },
        ],
      };
    }, [footerTranslateY]);

    /**
     * Animates the enclosing container of the WebView to force its top + bottom into viewable range when the header / footer show.
     * This is not the most performant of animations but thanks to reanimated, its possible and still looks smooth in normal use.
     */
    const animatedWebViewContainerStyle = useAnimatedStyle(() => {
      return {
        top: props.screenHeaderAnimationRef
          ? interpolate(
              props.screenHeaderAnimationRef.value,
              [0, screenHeaderHeight.value],
              [screenHeaderHeight.value + tabBarHeight, statusBarHeight.value],
              Extrapolation.CLAMP
            )
          : undefined,
        bottom: interpolate(
          footerTranslateY.value,
          [0, bottomActionBarHeight + webNavBarHeight],
          [bottomActionBarHeight + webNavBarHeight, 0],
          Extrapolation.CLAMP
        ),
      };
    }, [
      props.screenHeaderAnimationRef,
      screenHeaderHeight,
      tabBarHeight,
      footerTranslateY,
      statusBarHeight,
      bottomActionBarHeight,
      webNavBarHeight,
    ]);

    /**
     * Animates the enclosing container of the native view in reaction to header height changes to provide a smooth transition for
     * the timer status bar entering / exiting.
     *
     * Animated styles cannot be shared across views: https://docs.swmansion.com/react-native-reanimated/docs/api/hooks/useAnimatedStyle.
     * Since the number of tabs varies based on the screen type (In Kitchen vs Reciep Detail), we break the rule of hooks here since for a
     * given screen we know that the tab count will not change, and therefore the hooks will be called consistently across renders.
     */
    const routeContainerAnimationsInternal = range(routes.length).map(() =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useAnimatedStyle(() => {
        const screenHeaderAndTabBarHeight = screenHeaderHeight.value + tabBarHeight;

        return {
          minHeight: dimensions.height + (showSideBySideInstructions ? 0 : tabBarHeight + bareHeaderHeight),
          transform: [
            {
              translateY: screenHeaderAndTabBarHeight,
            },
          ],
          paddingBottom: bottomTabBarHeight + bottomActionBarHeight + screenHeaderAndTabBarHeight,
        };
      }, [
        screenHeaderHeight,
        tabBarHeight,
        dimensions.height,
        bottomActionBarHeight,
        bareHeaderHeight,
        bottomTabBarHeight,
        showSideBySideInstructions,
      ])
    );

    const routeContainerAnimations = useMemo(
      () => routeContainerAnimationsInternal,
      [
        screenHeaderHeight,
        tabBarHeight,
        dimensions.height,
        bottomActionBarHeight,
        bareHeaderHeight,
        bottomTabBarHeight,
        showSideBySideInstructions,
      ]
    );

    const renderRoute = useCallback(
      (route: { route: TabViewRoute }) => {
        const key = route.route.key as TabMenuItem;

        // Web view layout
        const webViewInsetTop = 0;
        const webViewPaddingTop = 0;
        const webViewPaddingBottom = bottomTabBarHeight;

        const renderSideBySideInstructions = showSideBySideInstructions && !!props.renderInstructions;

        const ingredients = (
          <AnimatedScrollView
            key="ingredients"
            ref={ingredientsRef}
            onScroll={ingredientsScrollHandler}
            animatedContainerStyle={routeContainerAnimations[0] ?? {}}
            initialScrollY={scrollPositions[0]?.scrollPosition.value ?? 0}
          >
            {props.renderIngredients?.(props.recipe)}
          </AnimatedScrollView>
        );

        const instructions = (
          <AnimatedScrollView
            key="instructions"
            ref={instructionsRef}
            onScroll={instructionsScrollHandler}
            animatedContainerStyle={routeContainerAnimations[1] ?? {}}
            initialScrollY={scrollPositions[1]?.scrollPosition.value ?? 0}
          >
            {props.renderInstructions?.(props.recipe)}
          </AnimatedScrollView>
        );

        switch (key) {
          case "ingredients": {
            return (
              !!props.renderIngredients && (
                <>
                  {renderSideBySideInstructions && (
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <View style={{ flex: 1, width: "50%" }}>{ingredients}</View>
                      <Separator orientation="column" />
                      <View style={{ flex: 1, width: "50%" }}>{instructions}</View>
                    </View>
                  )}
                  {!renderSideBySideInstructions && ingredients}
                </>
              )
            );
          }
          case "instructions": {
            return !!props.renderInstructions && instructions;
          }
          case "recipeInfo": {
            return (
              <>
                {!!props.webViewSessionId && (
                  <RecipeInfoWebRoute
                    key={`web_${key}`}
                    ref={webViewRef}
                    webViewSessionId={props.webViewSessionId}
                    hide={isReaderMode}
                    recipe={props.recipe}
                    insetTop={webViewInsetTop}
                    paddingTop={webViewPaddingTop}
                    paddingBottom={webViewPaddingBottom}
                    animatedContainerStyle={animatedWebViewContainerStyle}
                    onScroll={webViewScrollHandler}
                    onNavigationStateChange={props.onWebViewNavigationStateChange}
                  />
                )}
                <AnimatedScrollView
                  key={key}
                  ref={recipeInfoRef}
                  onScroll={recipeInfoScrollHandler}
                  animatedContainerStyle={routeContainerAnimations[routeContainerAnimations.length - 2] ?? {}}
                  initialScrollY={scrollPositions[scrollPositions.length - 2]?.scrollPosition.value ?? 0}
                  hide={!!props.webViewSessionId && !isReaderMode}
                  refreshControl={
                    props.refreshLibraryRecipe
                      ? getPullToRefresh(props.refreshLibraryRecipe, screenHeaderHeight.value)
                      : undefined
                  }
                >
                  <RecipeDetailContent
                    recipe={props.recipe}
                    toggleReaderMode={props.toggleReaderMode}
                    recipeScale={props.recipeScale}
                    setRecipeScale={props.setRecipeScale}
                    waitingScalingData={props.waitingFetchScalingData}
                    fetchScalingDataErrored={props.fetchScalingDataErrored}
                  />
                </AnimatedScrollView>
              </>
            );
          }
          case "notes": {
            return (
              !!props.renderNotes && (
                <AnimatedScrollView
                  key={key}
                  ref={notesRef}
                  onScroll={notesScrollHandler}
                  animatedContainerStyle={routeContainerAnimations[routeContainerAnimations.length - 1] ?? {}}
                  initialScrollY={scrollPositions[scrollPositions.length - 1]?.scrollPosition.value ?? 0}
                >
                  <NotesRoute recipe={props.recipe} renderNotes={props.renderNotes} />
                </AnimatedScrollView>
              )
            );
          }
          default:
            bottomThrow(key);
        }
      },
      [
        bottomTabBarHeight,
        props.webViewSessionId,
        isReaderMode,
        props.toggleReaderMode,
        animatedWebViewContainerStyle,
        webViewRef,
        props.recipe,
        webViewScrollHandler,
        props.onWebViewNavigationStateChange,
        recipeInfoRef,
        recipeInfoScrollHandler,
        routeContainerAnimations,
        scrollPositions,
        props.renderIngredients,
        ingredientsRef,
        ingredientsScrollHandler,
        props.renderInstructions,
        instructionsRef,
        instructionsScrollHandler,
        props.renderNotes,
        notesRef,
        notesScrollHandler,
        showSideBySideInstructions,
        props.refreshLibraryRecipe,
        screenHeaderHeight,
        props.recipeScale,
        props.setRecipeScale,
        props.waitingFetchScalingData,
        props.fetchScalingDataErrored,
      ]
    );

    const onIndexChange = useCallback(
      (index: number) => {
        setDisplayedRouteIndex(index);

        if (displayedRoutes[index]?.key === "notes") {
          props.onViewNotes?.();
        }

        // Only two states now which can be derived from single vs multiple tabs
        const context = displayedRoutes.length > 1 ? "cookingSession" : "recipeDetail";
        const title = displayedRoutes[index]?.title;
        if (title) {
          const event = reportRecipeDetailTabChanged({ recipe: props.recipe, context, tab: title });
          dispatch(analyticsEvent(event));
        }
      },
      [setDisplayedRouteIndex, props.recipe, displayedRoutes, dispatch, props.onViewNotes]
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          switchTab: (tab: TabMenuItem, scrollY?: number) => {
            if (scrollY !== undefined) {
              const scrollRef = switchReturn(tab, {
                ingredients: ingredientsRef.current,
                instructions: instructionsRef.current,
                recipeInfo: recipeInfoRef.current,
                notes: recipeInfoRef.current,
              });

              // we don't want this scroll to hide/show the screen chrome, so disable reactions
              pauseScrollReactions.value = true;

              // Adjust the scroll position based on the header/footer being present
              const headersPresent = !isHeaderHidden();
              const scrollAdjustment = headersPresent ? screenHeaderHeight.value + tabBarHeight : 0;

              scrollRef?.scrollTo({ y: scrollY - scrollAdjustment, animated: true });

              setTimeout(() => {
                // re-enable scroll reactions. The scroll call does not appear to be sync, but tests on the sim
                // showed a delay of around a few milliseconds between the call and the scroll position reaching
                // the requested place (with no animation). Adding a buffer that should still be imperceptable
                // and hopefully allow the scroll to finish
                pauseScrollReactions.value = showSideBySideInstructions;
              }, 500);
            }

            const index = switchReturn(tab, {
              ingredients: 0,
              instructions: 1 - (showSideBySideInstructions ? 1 : 0),
              recipeInfo: 2 - (showSideBySideInstructions ? 1 : 0),
              notes: 3 - (showSideBySideInstructions ? 1 : 0),
            });

            setDisplayedRouteIndex(index);
          },
        };
      },
      [
        tabBarHeight,
        ingredientsRef,
        instructionsRef,
        recipeInfoRef,
        pauseScrollReactions,
        setDisplayedRouteIndex,
        isHeaderHidden,
        screenHeaderHeight,
        showSideBySideInstructions,
      ]
    );

    const tabBar = useMemo<TabViewBarProps>(() => {
      if (displayedRoutes.length > 1) {
        return {
          type: "snapToScreenHeader",
          screenHeaderOffset: props.screenHeaderAnimationRef,
          badgeTabKeys: props.haveUnreadNotes ? ["notes"] : undefined,
        };
      }
      return "none";
    }, [displayedRoutes.length, props.haveUnreadNotes]);

    // -------- RENDER STARTS HERE --------
    return (
      <>
        <TabView
          index={displayedRouteIndex}
          onIndexChange={onIndexChange}
          routes={displayedRoutes}
          renderRoute={renderRoute}
          tabBar={tabBar}
          swipeEnabled={displayedRoutes.length > 1}
        />
        <Animated.View style={animatedFooterStyle}>
          {!!props.webViewSessionId && currentTabIsWebView && (
            <RecipeInfoWebNavBar
              sessionId={props.webViewSessionId}
              onPressGoBack={onPressGoBack}
              onPressGoForward={onPressGoForward}
              onPressRefresh={onPressRefresh}
              bottomOffset={bottomActionBarHeight}
            />
          )}
          {!!props.renderActionBar && props.renderActionBar()}
        </Animated.View>
        <BottomNotch />
      </>
    );
  })
);

type RecipeInfoWebRouteProps = {
  webViewSessionId: WebViewSessionId;
  onScroll: WebViewScrollEventHandler;
  onNavigationStateChange?: WebViewNavigationStateChangeHandler;
  insetTop: number;
  paddingTop: number;
  paddingBottom: number;
  animatedContainerStyle: ReturnType<typeof useAnimatedStyle>;
  hide?: boolean;
};

const RecipeInfoWebRoute = React.forwardRef<WebViewImperativeHandle, TabRouteProps & RecipeInfoWebRouteProps>(
  (props, ref) => {
    const url = useRecipeSourceUrl(props.recipe);

    // Debug + super-admin only
    const showPaywallStatusPill = useShowPaywallStatusPill();
    const { paywallStatus } = usePaywallStatus(props.recipe);

    const contentInsets = useMemo(() => ({ top: props.insetTop }), [props.insetTop]);
    const padding = useMemo(
      () => ({ top: props.paddingTop, bottom: props.paddingBottom }),
      [props.paddingTop, props.paddingBottom]
    );

    if (!url) {
      return null;
    }

    return (
      <Animated.View
        style={[
          StyleSheet.absoluteFillObject,
          props.hide ? { opacity: Opacity.transparent, zIndex: -1 } : {},
          props.animatedContainerStyle,
        ]}
      >
        <View style={[styles.webLoadingProgressBar, { top: contentInsets.top }]}>
          <WebViewLoadingProgressBar sessionId={props.webViewSessionId} />
        </View>
        <WebView
          ref={ref}
          sessionId={props.webViewSessionId}
          onScroll={props.onScroll}
          contentInsets={contentInsets}
          padding={padding}
          onNavigationStateChange={props.onNavigationStateChange}
        />
        {!!showPaywallStatusPill && (
          // Super admin only: display a small pill on the bottom right with the paywall status
          <View
            style={{
              position: "absolute",
              zIndex: 10,
              bottom: props.paddingBottom + 24,
              right: 24,
              paddingHorizontal: 12,
              borderRadius: 20,
              backgroundColor: globalStyleColors.colorAccentMid,
            }}
          >
            <TSecondary>{paywallStatus}</TSecondary>
          </View>
        )}
      </Animated.View>
    );
  }
);

const RecipeInfoWebNavBar = React.memo(
  (props: {
    sessionId: WebViewSessionId;
    onPressGoBack: () => void;
    onPressGoForward: () => void;
    onPressRefresh: () => void;
    bottomOffset: number;
  }) => {
    return (
      <View style={{ zIndex: 2 }}>
        <ContainerFadeIn>
          <WebViewNavBar
            sessionId={props.sessionId}
            onPressGoBack={props.onPressGoBack}
            onPressGoForward={props.onPressGoForward}
            onPressRefresh={props.onPressRefresh}
            bottomOffset={props.bottomOffset}
          />
        </ContainerFadeIn>
      </View>
    );
  }
);

interface NotesRouteProps {
  recipe: RecipeDetailProps["recipe"];
  renderNotes: NonNullable<RecipeDetailProps["renderNotes"]>;
}

const NotesRoute = React.memo((props: NotesRouteProps) => {
  return (
    <View style={{ paddingVertical: 1.5 * globalStyleConstants.unitSize }}>
      {"type" in props.recipe && props.recipe.type === "userRecipe" && props.renderNotes(props.recipe)}
    </View>
  );
});

const AnimatedScrollView = React.forwardRef<
  Animated.ScrollView,
  PropsWithChildren<{
    onScroll: ScrollEventHandler;
    animatedContainerStyle: ReturnType<typeof useAnimatedStyle>;
    backgroundColor?: string;
    initialScrollY?: number;
    hide?: boolean;
    refreshControl?: ScrollViewProps["refreshControl"];
  }>
>((props, ref) => {
  const svRef = useRef<Animated.ScrollView>(null);
  const [haveContentSize, setHaveContentSize] = useState(false);

  useImperativeHandle(ref, () => svRef.current!, [svRef]);

  useLayoutEffect(() => {
    // don't attempt to scroll until the content has been rendered
    if (haveContentSize) {
      if (props.initialScrollY) {
        svRef.current?.scrollTo({ y: props.initialScrollY, animated: false });
      }
    }
  }, [haveContentSize]);

  // we need to wait for the content size to be set before we scroll to props.initialScrollY (see above).
  // set a state var once we have a non-zero size to trigger the useLayoutEffect.
  const onContentSizeChange = useCallback(
    (size: number) => {
      if (size) {
        setHaveContentSize(true);
      }
    },
    [setHaveContentSize]
  );

  return (
    <View
      style={[
        StyleSheet.absoluteFill,
        props.hide ? { opacity: Opacity.transparent, zIndex: -1 } : {},
        { backgroundColor: props.backgroundColor ?? "white" },
      ]}
    >
      <Animated.ScrollView
        ref={svRef}
        onScroll={props.onScroll}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        bounces
        onContentSizeChange={onContentSizeChange}
        keyboardDismissMode="on-drag"
        refreshControl={props.refreshControl}
      >
        <Animated.View style={props.animatedContainerStyle}>{props.children}</Animated.View>
      </Animated.ScrollView>
    </View>
  );
});

const styles = StyleSheet.create({
  tabBarContainer: {
    left: 0,
    right: 0,
    position: "absolute",
    zIndex: 1,
  },
  webLoadingProgressBar: {
    position: "absolute",
    left: 0,
    right: 0,
    height: webViewConstants.progressBarHeight,
    zIndex: 2,
  },
});
