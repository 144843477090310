import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useDispatch } from "../../lib/redux/Redux";
import { RecipeInstructionId, RecipeSectionId, AppUserRecipe } from "@eatbetter/recipes-shared";
import {
  useAuthedUser,
  useAuthedUserId,
  useCookingSessionFontScale,
  usePushPermission,
} from "../../lib/system/SystemSelectors";
import {
  CookingSessionId,
  CookingSessionIngredientIndex,
  CookingSessionInstructionIndex,
  CookingSessionInstructionTimer,
} from "@eatbetter/cooking-shared";
import {
  cookingSessionMounted,
  CookingTimerId,
  ingredientStatusUpdated,
  viewedRecipeNotes,
} from "../../lib/cooking/CookingSessionsSlice";
import { Haptics } from "../Haptics";
import {
  useCookingSessionSourceRecipe,
  useCookingTimer,
  useIngredientCompleted,
  useIngredientSectionIds,
  useIngredientSectionItemCount,
  useIngredientSectionTitle,
  useIngredient,
  useInstructionSectionIds,
  useInstructionSectionItemCount,
  useInstructionSectionTitle,
  useInstructionTimers,
  UserIdsForInstruction,
  useUserIdsForInstruction,
  useCookingSessionRecipeScale,
  useCookingSessionRecipeUnitConversion,
  useInstruction,
} from "../../lib/cooking/CookingSessionsSelectors";
import { Spacer } from "../Spacer";
import { Animated, LayoutChangeEvent, StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { FontLineHeight, FontSize, TBody, getScaledFont, useFontFamilyMap } from "../Typography";
import { ContainerPadded } from "../Containers";
import { RecipeSectionHeading } from "./RecipeSectionHeading";
import { bottomNop, filterOutFalsy, range } from "@eatbetter/common-shared";
import { Pressable } from "../Pressable";
import { Photo } from "../Photo";
import { RecipeDetail, RecipeDetailHandle } from "./RecipeDetail";
import { SharedValue } from "react-native-reanimated";
import { startRecipeTimer } from "../../lib/cooking/CookingTimerThunks";
import { useCookingTimerStatus } from "../../lib/cooking/CookingTimerTick";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { RecipeNotesEdit } from "./RecipeNotesEdit";
import { instructionTapped } from "../../lib/cooking/CookingSessionsThunks";
import { WebViewNavigationStateChangeHandler } from "../WebView";
import { NotificationPermission, notificationPermissionConstants } from "./NotificationPermission";
import { reportRecipePaywallHit } from "../../lib/recipes/RecipesThunks";
import { bottomActionBarConstants } from "../BottomActionBar";
import { InKitchenRecipeActionBar } from "./InKitchenRecipeActionBar";
import { usePaywallStatus } from "../PaywallDetector";
import { useRecipeInstructionsAccess } from "../../lib/recipes/UseRecipeInstructionsAccess";
import { HiddenWebInstructionsMessage } from "./HiddenInstructionsMessage";
import { useRecipeSourceUrl } from "../../lib/recipes/RecipesSelectors";
import { useWebViewSession } from "../../lib/webview/WebViewHooks";
import { SelectedCircle } from "../SelectedCircle";
import { useScaled, useScaledInstruction } from "../../lib/recipes/UseScaled";
import { ModifiableRecipeText, ModifiedRecipeText, RecipeText } from "./RecipeText";

const strings = {
  noIngredients: "(No ingredients)",
  noInstructions: "(No instructions)",
  hiddenBookInstructions: ["Please refer to ", " for instructions."],
  fallbackBookName: "the book",
  hiddenPaywalledInstructions: ["Please sign in to ", " to see recipe instructions."],
  fallbackSiteName: "the site",
  signInToSiteButton: (siteName: string) => `Sign in to ${siteName}`,
  emptySection: "(Empty section)",
};

export interface InKitchenRecipeHandle {
  getCookingSessionId(): CookingSessionId;
  focusInstruction(instructionSectionId: RecipeSectionId, instructionId: RecipeInstructionId): void;
  switchToRecipeInfoTab(): void;
}

export interface InKitchenRecipeProps {
  cookingSessionId: CookingSessionId;
  screenHeaderAnimationRef: SharedValue<number>;
  switchCookingSession?: () => void;
  onWebViewNavStateChanged?: WebViewNavigationStateChangeHandler;
  haveUnreadNotes?: boolean;
}

export const InKitchenRecipe = React.memo(
  React.forwardRef<InKitchenRecipeHandle, InKitchenRecipeProps>((props, ref) => {
    const dispatch = useDispatch();
    const userId = useAuthedUserId();
    const cookingSessionId = props.cookingSessionId;
    const recipeDetailRef = useRef<RecipeDetailHandle>(null);
    const instructionScrollPositions = useRef<Record<RecipeInstructionId, number>>({}).current;
    const sourceRecipe = useCookingSessionSourceRecipe(cookingSessionId);
    const sourceRecipeUrl = useRecipeSourceUrl(sourceRecipe);
    const webViewSessionId = useWebViewSession(sourceRecipeUrl);
    const { paywallIsUp } = usePaywallStatus(sourceRecipe);
    const recipeScale = useCookingSessionRecipeScale(props.cookingSessionId);

    const alreadyReportedPaywallHit = useRef(false);

    useEffect(() => {
      if (!alreadyReportedPaywallHit.current && paywallIsUp && sourceRecipe) {
        dispatch(reportRecipePaywallHit(sourceRecipe, "Cooking Session"));
        alreadyReportedPaywallHit.current = true;
      }
    }, [paywallIsUp, !!sourceRecipe]);

    useEffect(() => {
      if (userId && cookingSessionId) {
        dispatch(cookingSessionMounted({ userId, cookingSessionId }));
      }
    }, [userId, cookingSessionId]);

    const onPressIngredient = useCallback(
      (sectionId: RecipeSectionId, ingredientId: CookingSessionIngredientIndex) => {
        if (!cookingSessionId) {
          return;
        }

        dispatch(
          ingredientStatusUpdated({
            cookingSessionId,
            sectionId,
            ingredientId,
          })
        );
        Haptics.feedback("itemStatusChanged");
      },
      [cookingSessionId, dispatch]
    );

    const onPressInstruction = useCallback(
      (instructionIndex: CookingSessionInstructionIndex, instructionSectionId: RecipeSectionId) => {
        if (!userId || !cookingSessionId) {
          return;
        }

        dispatch(
          instructionTapped({
            cookingSessionId,
            userId,
            instructionSectionId,
            instructionIndex,
          })
        );
      },
      [cookingSessionId, userId, dispatch]
    );

    const onPressGoBackAndSignIn = useCallback(() => {
      recipeDetailRef.current?.switchTab("recipeInfo");
    }, [recipeDetailRef]);

    const renderIngredients = useCallback(
      () => <IngredientsRoute cookingSessionId={cookingSessionId} onPressIngredient={onPressIngredient} />,
      [cookingSessionId, onPressIngredient]
    );

    const onInstructionLayout = useCallback(
      (instructionId: RecipeInstructionId, yPosition: number) => {
        // save the y position of each instruction so we can scroll to the appropriate location
        // if we need to navigate there from a timer
        instructionScrollPositions[instructionId] = yPosition;
      },
      [instructionScrollPositions]
    );

    const renderInstructions = useCallback(
      () => (
        <InstructionsRoute
          cookingSessionId={cookingSessionId}
          onPressInstruction={onPressInstruction}
          onInstructionLayout={onInstructionLayout}
          onPressGoBackAndSignIn={onPressGoBackAndSignIn}
        />
      ),
      [cookingSessionId, onPressInstruction, onInstructionLayout, paywallIsUp, onPressGoBackAndSignIn]
    );

    const renderNotes = useCallback(
      (recipe: AppUserRecipe) => (
        <RecipeNotesEdit recipeId={recipe.id} location="cookingSession" autoFocusMode="none" />
      ),
      []
    );

    const onViewNotes = useCallback(() => {
      dispatch(viewedRecipeNotes({ cookingSessionId }));
    }, [dispatch, cookingSessionId]);

    const renderActionBar = useCallback(() => {
      return <InKitchenRecipeActionBar cookingSessionId={cookingSessionId} />;
    }, [cookingSessionId]);

    useImperativeHandle(
      ref,
      () => {
        return {
          getCookingSessionId: (): CookingSessionId => {
            // This is here specifically for the RecipeInKitchenScreen, where we keep only a single ref for potentially
            // multiple cooking sessions. This allows the screen to verify the ref is updated correctly before taking
            // action
            return cookingSessionId;
          },
          focusInstruction: (instructionSectionId: RecipeSectionId, instructionId: RecipeInstructionId): void => {
            if (userId) {
              dispatch(
                instructionTapped({
                  cookingSessionId,
                  userId,
                  instructionSectionId,
                  instructionId,
                })
              );
            }

            const y = instructionScrollPositions[instructionId];
            recipeDetailRef.current?.switchTab("instructions", y);
          },
          switchToRecipeInfoTab: (): void => {
            recipeDetailRef.current?.switchTab("recipeInfo");
          },
        };
      },
      [recipeDetailRef, dispatch, instructionScrollPositions, cookingSessionId, userId]
    );

    if (!sourceRecipe) {
      return null;
    }

    return (
      <RecipeDetail
        ref={recipeDetailRef}
        webViewSessionId={webViewSessionId}
        recipe={sourceRecipe}
        renderIngredients={renderIngredients}
        renderInstructions={renderInstructions}
        renderNotes={renderNotes}
        renderActionBar={renderActionBar}
        actionBarHeight={bottomActionBarConstants.height}
        screenHeaderAnimationRef={props.screenHeaderAnimationRef}
        key={cookingSessionId}
        onWebViewNavigationStateChange={props.onWebViewNavStateChanged}
        haveUnreadNotes={props.haveUnreadNotes}
        onViewNotes={onViewNotes}
        recipeScale={recipeScale}
      />
    );
  })
);

interface IngredientRouteProps {
  cookingSessionId: CookingSessionId | undefined;
  onPressIngredient: (sectionId: RecipeSectionId, index: CookingSessionIngredientIndex) => void;
}

const IngredientsRoute = React.memo((props: IngredientRouteProps) => {
  const ingredientSectionIds = useIngredientSectionIds(props.cookingSessionId);

  return (
    <>
      {ingredientSectionIds.length < 1 && (
        <View style={{ paddingHorizontal: 20 }}>
          <TBody>{strings.noIngredients}</TBody>
        </View>
      )}
      {ingredientSectionIds.length > 0 &&
        ingredientSectionIds.map((sectionId, idx) => {
          return (
            !!props.cookingSessionId && (
              <IngredientSection
                cookingSessionId={props.cookingSessionId}
                sectionId={sectionId}
                sectionIndex={idx}
                onPress={props.onPressIngredient}
                key={sectionId}
              />
            )
          );
        })}
      <Spacer vertical={2.5} />
    </>
  );
});

interface InstructionsRouteProps {
  cookingSessionId: CookingSessionId | undefined;
  onPressInstruction: (index: CookingSessionInstructionIndex, sectionId: RecipeSectionId) => void;
  onInstructionLayout: (instructionId: RecipeInstructionId, yPosition: number) => void;
  onPressGoBackAndSignIn: () => void;
}

const InstructionsRoute = React.memo((props: InstructionsRouteProps) => {
  const instructionSectionIds = useInstructionSectionIds(props.cookingSessionId);
  const sourceRecipe = useCookingSessionSourceRecipe(props.cookingSessionId);
  const instructionsAccess = useRecipeInstructionsAccess(sourceRecipe);

  const renderInstructions = () => {
    switch (instructionsAccess.type) {
      case "show": {
        return (
          <>
            {instructionSectionIds.length === 0 && <InstructionsEmptyState message={strings.noInstructions} />}
            {instructionSectionIds.length > 0 &&
              instructionSectionIds.map((sectionId, idx) => {
                return (
                  !!props.cookingSessionId && (
                    <InstructionSection
                      cookingSessionId={props.cookingSessionId}
                      sectionId={sectionId}
                      sectionIndex={idx}
                      onPress={props.onPressInstruction}
                      onInstructionLayout={props.onInstructionLayout}
                      key={sectionId}
                    />
                  )
                );
              })}
          </>
        );
      }
      case "hide": {
        return (
          <View style={{ padding: globalStyleConstants.defaultPadding }}>
            <HiddenWebInstructionsMessage
              context="cookingSession"
              hiddenReason={instructionsAccess.reason}
              onPressLink={props.onPressGoBackAndSignIn}
              publisherName={sourceRecipe?.publisher?.name}
            />
          </View>
        );
      }
      default: {
        bottomNop(instructionsAccess);
        return <InstructionsEmptyState message={strings.noInstructions} />;
      }
    }
  };

  return (
    <>
      {renderInstructions()}
      <Spacer vertical={2} />
    </>
  );
});

const InstructionsEmptyState = React.memo((props: { message: string | (() => React.ReactNode) }) => {
  return (
    <View style={{ paddingHorizontal: 20, paddingTop: 2 * globalStyleConstants.unitSize }}>
      {typeof props.message === "string" && <TBody>{props.message}</TBody>}
      {typeof props.message !== "string" && props.message()}
    </View>
  );
});

interface IngredientSectionProps {
  cookingSessionId: CookingSessionId;
  sectionId: RecipeSectionId;
  sectionIndex: number;
  onPress: (sectionId: RecipeSectionId, index: CookingSessionIngredientIndex) => void;
}

const IngredientSection = React.memo((props: IngredientSectionProps) => {
  const count = useIngredientSectionItemCount(props.cookingSessionId, props.sectionIndex);
  const title = useIngredientSectionTitle(props.cookingSessionId, props.sectionIndex);
  const fontScale = useCookingSessionFontScale();

  return (
    <>
      {(!!title || props.sectionIndex !== 0) && (
        <ContainerPadded horizontal={20 / 12} top={2.5}>
          <RecipeSectionHeading sectionIndex={props.sectionIndex} text={title} scale={fontScale} />
        </ContainerPadded>
      )}
      {!count && (
        <View style={{ paddingHorizontal: 20 }}>
          <TBody>{props.sectionIndex === 0 ? strings.noIngredients : strings.emptySection}</TBody>
        </View>
      )}
      {!!count &&
        range(count - 1).map(idx => {
          return (
            <IngredientItem
              key={`${props.sectionId}_${idx}`}
              cookingSessionId={props.cookingSessionId}
              index={idx as CookingSessionIngredientIndex}
              sectionId={props.sectionId}
              sectionIndex={props.sectionIndex}
              onPress={props.onPress}
            />
          );
        })}
    </>
  );
});

interface InstructionSectionProps {
  cookingSessionId: CookingSessionId;
  sectionId: RecipeSectionId;
  sectionIndex: number;
  onPress: (index: CookingSessionInstructionIndex, sectionId: RecipeSectionId) => void;
  onInstructionLayout: (instructionId: RecipeInstructionId, yPosition: number) => void;
}

const InstructionSection = React.memo((props: InstructionSectionProps) => {
  const count = useInstructionSectionItemCount(props.cookingSessionId, props.sectionIndex);
  const title = useInstructionSectionTitle(props.cookingSessionId, props.sectionIndex);
  const scale = useCookingSessionFontScale();

  return (
    <>
      {/* {props.sectionIndex !== 0 && <Spacer vertical={2} />} */}
      {(!!title || props.sectionIndex !== 0) && (
        <ContainerPadded horizontal={20 / 12} top={2.5} bottom={1}>
          <RecipeSectionHeading sectionIndex={props.sectionIndex} text={title} scale={scale} />
        </ContainerPadded>
      )}
      {!count && (
        <View style={{ paddingHorizontal: 20, paddingTop: 2 * globalStyleConstants.unitSize }}>
          <TBody>{props.sectionIndex === 0 ? strings.noInstructions : strings.emptySection}</TBody>
        </View>
      )}
      {!!count &&
        range(count - 1).map(idx => {
          return (
            !!props.cookingSessionId && (
              <InstructionItem
                key={`${props.sectionId}_${idx}`}
                cookingSessionId={props.cookingSessionId}
                index={idx as CookingSessionInstructionIndex}
                sectionId={props.sectionId}
                sectionIndex={props.sectionIndex}
                onPress={props.onPress}
                onInstructionLayout={props.onInstructionLayout}
              />
            )
          );
        })}
    </>
  );
});

const IngredientItem = React.memo(
  (props: {
    cookingSessionId: CookingSessionId;
    sectionId: RecipeSectionId;
    sectionIndex: number;
    index: CookingSessionIngredientIndex;
    onPress: (sectionId: RecipeSectionId, index: CookingSessionIngredientIndex) => void;
  }) => {
    const ingredient = useIngredient(props.cookingSessionId, props.sectionIndex, props.index);
    const isCompleted = useIngredientCompleted(props.cookingSessionId, props.sectionId, props.index);
    const fontScale = useCookingSessionFontScale();

    const recipeScale = useCookingSessionRecipeScale(props.cookingSessionId);
    const recipeUnits = useCookingSessionRecipeUnitConversion(props.cookingSessionId);
    const tokens = useScaled(ingredient, recipeScale, recipeUnits);

    const onPress = useCallback(() => {
      props.onPress(props.sectionId, props.index);
    }, [props.onPress, props.sectionId, props.index]);

    if (!ingredient) {
      return null;
    }

    return (
      <Pressable noFeedback onPress={onPress}>
        <Spacer vertical={2.5} />
        <View
          style={{
            flexDirection: "row",
            paddingHorizontal: 20,
            alignItems: "center",
          }}
        >
          <View>
            {/* Without a wrapping view, flexbox doesn't work properly on web */}
            <SelectedCircle isSelected={isCompleted} />
          </View>
          <View style={{ paddingLeft: globalStyleConstants.unitSize, flexShrink: 1 }}>
            <ModifiableRecipeText
              fontSize="body"
              tokens={tokens}
              fontScale={fontScale}
              opacity={isCompleted ? "light" : undefined}
              strikethrough={isCompleted}
              tapDisabled={isCompleted}
            />
          </View>
        </View>
      </Pressable>
    );
  }
);

const InstructionItem = React.memo(
  (props: {
    cookingSessionId: CookingSessionId;
    sectionId: RecipeSectionId;
    sectionIndex: number;
    index: CookingSessionInstructionIndex;
    onPress: (index: CookingSessionInstructionIndex, sectionId: RecipeSectionId) => void;
    onInstructionLayout: (instructionId: RecipeInstructionId, yPosition: number) => void;
  }) => {
    const user = useAuthedUser();
    const userIds: UserIdsForInstruction = useUserIdsForInstruction(
      user?.userId,
      props.cookingSessionId,
      props.sectionId,
      props.index
    );

    const selectedByHouseholdUsers = user
      ? filterOutFalsy(
          userIds.otherUserIds.map(id => {
            return user.household.find(h => h.userId === id);
          })
        )
      : [];

    const onPress = useCallback(() => {
      props.onPress(props.index, props.sectionId);
    }, [props.index, props.sectionId]);

    const sourceRecipe = useCookingSessionSourceRecipe(props.cookingSessionId);

    const onLayout = useCallback(
      (e: LayoutChangeEvent) => {
        const id = sourceRecipe?.instructions.sections[props.sectionIndex]?.items[props.index]?.id;
        if (id) {
          props.onInstructionLayout(id, e.nativeEvent.layout.y);
        }
      },
      [props.onInstructionLayout, sourceRecipe, props.sectionIndex, props.index]
    );

    const instructionText = (
      <View>
        <InstructionText
          cookingSessionId={props.cookingSessionId}
          sectionId={props.sectionId}
          sectionIndex={props.sectionIndex}
          instructionIndex={props.index}
          onPress={onPress}
          containingInstructionSelected={userIds.currentUserSelected}
        />
      </View>
    );

    return (
      <Pressable noFeedback onPress={onPress} onLayout={onLayout}>
        <View style={[{ paddingHorizontal: 20 }, userIds.currentUserSelected ? styles.instructionSelected : {}]}>
          <Spacer vertical={1} />
          {selectedByHouseholdUsers.length > 0 ? (
            <View style={{ flexDirection: "row" }}>
              {selectedByHouseholdUsers.map(u => (
                <View key={u.userId} style={{ marginRight: globalStyleConstants.unitSize }}>
                  <Photo source={u.photo} style="avatarSmall" sourceSize="w288" />
                  <Spacer vertical={0.5} />
                </View>
              ))}
            </View>
          ) : null}
          {instructionText}
          <Spacer vertical={1} />
        </View>
      </Pressable>
    );
  }
);

const InstructionText = React.memo(
  (props: {
    cookingSessionId: CookingSessionId;
    sectionIndex: number;
    sectionId: RecipeSectionId;
    instructionIndex: number;
    containingInstructionSelected: boolean;
    onPress?: () => void;
  }) => {
    const instruction = useInstruction(props.cookingSessionId, props.sectionIndex, props.instructionIndex);
    const timers = useInstructionTimers(props.cookingSessionId, props.sectionId, instruction?.id) ?? [];
    const recipeScale = useCookingSessionRecipeScale(props.cookingSessionId);
    const recipeUnits = useCookingSessionRecipeUnitConversion(props.cookingSessionId);
    const tokens = useScaledInstruction(instruction, timers, recipeScale, recipeUnits);
    const fontScale = useCookingSessionFontScale();

    if (!instruction) {
      return null;
    }

    const children = tokens.map((token, idx) => {
      if (token.type === "text") {
        if (token.isModified || !!token.tooltipInfo) {
          return (
            <ModifiedRecipeText
              key={`${idx}_${token.text}`}
              changedTextToken={token}
              fontSize="body"
              fontScale={fontScale}
            />
          );
        }
        return token.text;
      }

      return (
        <InlineInstructionTimer
          cookingSessionId={props.cookingSessionId}
          text={token.text}
          fontScale={fontScale}
          timer={token.timer}
          onPress={props.onPress}
          key={`${idx}_${token.text}`}
          containingInstructionSelected={props.containingInstructionSelected}
        />
      );
    });

    return (
      <RecipeText fontSize="body" fontScale={fontScale}>
        {children}
      </RecipeText>
    );
  }
);

const InlineInstructionTimer = React.memo(
  (props: {
    cookingSessionId: CookingSessionId;
    text: string;
    fontScale: number | undefined;
    timer: CookingSessionInstructionTimer;
    containingInstructionSelected: boolean;
    onPress?: () => void;
  }) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const notificationPermission = usePushPermission();

    // the timer ID must be deterministic in the event the app restarts. In that case, the timer will be persisted in state and this component
    // needs to know the ID to figure out if it exists.
    const timerId = useRef(
      [props.cookingSessionId, props.timer.sectionId, props.timer.instructionId, props.timer.range[0]].join(
        "_"
      ) as CookingTimerId
    ).current;

    const timer = useCookingTimer(timerId);
    const status = useCookingTimerStatus(timerId);

    const onPress = useCallback(() => {
      const startTimer = () => {
        Haptics.feedback("operationSucceeded");
        dispatch(
          startRecipeTimer({
            id: timerId,
            cookingSessionId: props.cookingSessionId,
            sectionId: props.timer.sectionId,
            instructionId: props.timer.instructionId,
            minSeconds: props.timer.minSeconds,
            maxSeconds: props.timer.maxSeconds,
            substringRange: props.timer.range,
          })
        );
      };
      if (timer) {
        Haptics.feedback("tapControl");
        screen.nav.modal(navTree.get.screens.timers);
      } else if (notificationPermission?.havePermission !== true) {
        screen.nav.modal(navTree.get.screens.bottomSheet, {
          content: <NotificationPermission context="timers" onGranted={startTimer} />,
          height: notificationPermissionConstants.height,
          disableGestureDismissal: true,
        });
      } else {
        startTimer();
      }

      props.onPress?.();
    }, [
      props.onPress,
      timer,
      timerId,
      screen.nav.modal,
      dispatch,
      notificationPermission?.havePermission,
      startRecipeTimer,
    ]);

    const color =
      status?.status === "complete"
        ? globalStyleColors.colorTimerAction
        : status?.status === "running"
        ? globalStyleColors.colorTimerAction
        : globalStyleColors.colorTextLink;
    const weight = "medium";

    const fontFamilyMap = useFontFamilyMap();

    const showTimerHint = false;
    const pulseAnimation = useRef(new Animated.Value(1)).current;

    // Show timer tap hint (single pulse opacity animation on tap) if user has not used timers before
    useEffect(() => {
      if (!showTimerHint) {
        return;
      }

      const heartbeat = Animated.sequence([
        Animated.timing(pulseAnimation, {
          delay: 300,
          toValue: 0.25,
          duration: 0,
          useNativeDriver: false,
        }),
        Animated.timing(pulseAnimation, {
          toValue: 1,
          duration: 500,
          useNativeDriver: false,
        }),
      ]);

      if (props.containingInstructionSelected && !status) {
        heartbeat.start();
      }
    }, [props.containingInstructionSelected]);

    const fontSize = FontSize.body;
    const lineHeight = FontLineHeight.body;

    const { fontSize: scaledFontSize, lineHeight: scaledLineHeight } = useMemo(() => {
      return getScaledFont(fontSize, lineHeight, "upOnly", props.fontScale);
    }, [fontSize, lineHeight, props.fontScale]);

    return (
      <Animated.Text
        onPress={onPress}
        style={{
          opacity: pulseAnimation,
          fontFamily: fontFamilyMap.sansSerifMedium,
          fontSize: scaledFontSize,
          lineHeight: scaledLineHeight,
          color,
        }}
        suppressHighlighting
        allowFontScaling={false}
      >
        {props.text}
        {!!status && (
          <TBody scale={props.fontScale} enableFontScaling="upOnly" fontWeight={weight}>
            {" ("}
          </TBody>
        )}
        {!!status && (
          <TBody scale={props.fontScale} enableFontScaling="upOnly" font="monospace" fontWeight={weight}>
            {status.defaultDisplay}
          </TBody>
        )}
        {!!status && (
          <TBody scale={props.fontScale} enableFontScaling="upOnly" fontWeight={weight}>
            {")"}
          </TBody>
        )}
      </Animated.Text>
    );
  }
);

const styles = StyleSheet.create({
  instructionSelected: {
    backgroundColor: globalStyleColors.rgba("colorAccentMid"),
  },
});
